import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { baseURL } from "../../redux/api";

const PreviewImageModal = ({
  open,
  setOpen,
  projectImages,
  selectedImageIndex,
  setSelectedImageIndex,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  useEffect(() => {
    setStartIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-40" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel className="  z-40  w-full fixed mx-auto rounded-lg pt-5 pb-4 transition-all h-auto ">
                <div className=" absolute top-10 right-0 md:right-72 lg:right-72 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-10 ">
                  <div className=" sm:text-left">
                    <div className="w-10/12 md:w-6/12 lg:w-6/12 max-w-screen-lg mx-auto p-4">
                      <ImageGallery
                        items={
                          projectImages?.map((img) => ({
                            original: `${baseURL}s3/images/${img?.key}`,
                            thumbnail: `${baseURL}s3/images/${img?.key}`,
                          })) || []
                        }
                        showNav={true}
                        showThumbnails={true}
                        onSlide={(index) => {
                          setSelectedImageIndex(index);
                        }}
                        startIndex={startIndex}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        renderLeftNav={(onClick, disabled) => (
                          <TfiAngleLeft
                            onClick={onClick}
                            disabled={disabled}
                            className={`lg:text-5xl md:lg:text-5xl text-3xl text-white top-1/2 transform -translate-y-1/2 absolute z-50 lg:-left-14 -left-10 md:-left-14 ${
                              disabled
                                ? "opacity-50 cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          />
                        )}
                        renderRightNav={(onClick, disabled) => (
                          <TfiAngleRight
                            onClick={onClick}
                            disabled={disabled}
                            className={`lg:text-5xl lg:text-5xl text-3xl text-white top-1/2 transform -translate-y-1/2 absolute z-50 lg:-right-14 -right-10 md:-right-14 ${
                              disabled
                                ? "opacity-50 cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PreviewImageModal;
