export const isValidZipCode = (zip) => {
  return /^\d{5}(-\d{4})?$/.test(zip);
};

export const isEmailValid = (email) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
};

export const isPhoneNumberValid = (phone) => {
  return /^\+?([0-9]{1,4})?[-.\s]?(\(?[0-9]{3}\)?)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/.test(
    phone
  );
};
