import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { contactRequests } from "../../redux/api";
import { useParams } from "react-router-dom";
import { failure, success } from "../../utils/notification";

const initialData = {
  name: "",
  email: "",
  phoneNo: "",
  message: "",
  user: "",
};
const ContactPopup = ({ id, user, open, setOpen }) => {
  const scaleClass = open ? "scale-100" : "scale-0";
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      user: id,
    }));
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    contactRequests(formData)
      .then((res) => {
        setFormData(initialData);
        setOpen(false);
        success("Successful", "Email has been sent to the contact person.");
      })
      .catch((err) => {
        failure("Oops", err?.response?.data?.message);
        console.log(err);
      });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-50" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel
                className={`z-50 ${scaleClass} w-80 md:w-[33rem] lg:w-[33rem] fixed mx-auto md:right-4 lg:right-4 lg:top-48 md:top-48 top-32 mt-20 lg:my-9 md:my-9 rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all  sm:w-96  h-auto`}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="px-4">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {`Contact ${user?.firstName} ${user?.lastName}`}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="pt-5">
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="lg:h-96 md:h-96 h-72 overflow-y-auto">
                        <div className="px-5">
                          <div>
                            <label
                              className="text-base text-gray-700"
                              htmlFor="name"
                            >
                              Name:
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              className="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3 my-2"
                            />
                          </div>
                          <div>
                            <label
                              className="text-base text-gray-700"
                              htmlFor="email"
                            >
                              Email:
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3 my-2"
                            />
                          </div>
                          <div>
                            <label
                              className="text-base text-gray-700"
                              htmlFor="phoneNo"
                            >
                              Phone:
                            </label>
                            <input
                              type="tel"
                              id="phoneNo"
                              name="phoneNo"
                              value={formData.phoneNo}
                              onChange={handleChange}
                              className="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3 my-2"
                            />
                          </div>
                          <div>
                            <label
                              className="text-base text-gray-700"
                              htmlFor="message"
                            >
                              Message:
                            </label>
                            <textarea
                              rows={5}
                              id="message"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              className="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3 my-2"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="border-t border-gray-200 p-5">
                        <button
                          className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2.5 px-3 rounded-md"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ContactPopup;
