import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";

const TermsAndConditions = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="items-center min-h-screen h-screen">
      <div className="relative mx-auto  max-w-[1718px] min-h-screen h-screen">
        <Navigation />
        <div className="px-0 md:px-5 lg:px-5 min-h-full">
          <div
            name="termly-embed"
            data-id="e600bcc3-c8df-42fc-9ab9-b89a15d947e2"
            data-type="iframe"
            id="termly-embed"
          ></div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndConditions;
