import { Autocomplete } from "@react-google-maps/api";
import React, { useState, useEffect } from "react";
import { getBuildingRoofSizeAndImage } from "../../../redux/api";

const CalculateRoofArea = ({
  formData,
  setFormData,
  // handleChange,
  handleNextStep,
  setIsNextDisabled,
}) => {
  const [autoComplete, setAutoComplete] = useState(null);

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };
  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      if (!p?.geometry?.location) {
        console.error("Place object does not contain geometry or location.");
        return;
      }
      const latlng = {
        lat: p?.geometry?.location?.lat(),
        lng: p?.geometry?.location?.lng(),
      };

      let zipCode = "";
      if (p.address_components) {
        for (const component of p.address_components) {
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
            break;
          }
        }
      }

      getBuildingRoofSizeAndImage(latlng?.lat, latlng?.lng)
        .then((res) => {
          setFormData({
            ...formData,
            coordinates: latlng,
            address: p?.formatted_address,
            addressZipCode: zipCode,
            estimatedRoofArea:
              parseInt(
                res?.data?.buildingInsights?.solarPotential?.wholeRoofStats
                  ?.areaMeters2
              ) * 10.764,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAddressChange = (e) => {
    setFormData({
      ...formData,
      address: e.target.value,
    });
  };

  useEffect(() => {
    setIsNextDisabled(!formData?.address);
  }, [formData?.address, setIsNextDisabled]);

  return (
    <>
      <div className="h-full w-full flex items-center justify-start">
        <div className="w-full">
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              name="text"
              id="search"
              className="w-full p-2 mt-1 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-green-500 focus:border-green-500 focus:outline-none sm:text-sm"
              placeholder="Enter Address"
              value={formData?.address || ""}
              onChange={handleAddressChange}
            />
          </Autocomplete>
        </div>
      </div>
    </>
  );
};

export default CalculateRoofArea;
