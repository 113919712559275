export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-numeric characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  let formattedNumber = cleaned;

  if (cleaned.length > 3 && cleaned.length <= 6) {
    formattedNumber = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else if (cleaned.length > 6) {
    formattedNumber = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
      3,
      6
    )}-${cleaned.slice(6, 10)}`;
  }

  return formattedNumber;
};
