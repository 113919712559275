import * as type from "../types";

const workTypeReducer = (workTypes = [], action) => {
  switch (action.type) {
    case type.CREATE_WORKTYPE:
      return [action.payload, ...workTypes];
    case type.GET_WORKTYPES:
      return action.payload.reverse();
    case type.DELETE_WORKTYPE:
      return workTypes.filter((workType) => workType._id !== action.payload);
    case type.UPDATE_WORKTYPE:
      return workTypes.map((workType) =>
        workType._id === action.payload._id ? action.payload : workType
      );
    default:
      return workTypes;
  }
};

export default workTypeReducer;
