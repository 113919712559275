import * as type from "../types";

const companyReducer = (companies = [], action) => {
  switch (action.type) {
    case type.CREATE_COMPANY:
      return [action.payload, ...companies];
    case type.GET_COMPANIES:
      return action.payload.reverse();
    case type.DELETE_COMPANY:
      return companies.filter((company) => company._id !== action.payload);
    case type.UPDATE_COMPANY:
      return companies.map((company) =>
        company._id === action.payload._id ? action.payload : company
      );
    default:
      return companies;
  }
};

export default companyReducer;
