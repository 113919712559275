import * as type from "../types";

const contactReducer = (contacts = [], action) => {
  switch (action.type) {
    case type.CREATE_CONTACT:
      return [action.payload, ...contacts];
    case type.GET_CONTACTS:
      return action.payload.reverse();
    case type.DELETE_CONTACT:
      return contacts.filter((contact) => contact._id !== action.payload);
    case type.UPDATE_CONTACT:
      return contacts.map((contact) =>
        contact._id === action.payload._id ? action.payload : contact
      );
    default:
      return contacts;
  }
};

export default contactReducer;
