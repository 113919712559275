import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";

const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="items-center min-h-screen h-screen">
      <div className="relative mx-auto  max-w-[1718px] min-h-screen h-screen">
        <Navigation />
        <div className="px-0 md:px-5 lg:px-5 min-h-full">
          <div
            name="termly-embed"
            data-id="d1434834-9736-419a-a02e-1234d85c92da"
            data-type="iframe"
            id="termly-embed"
          ></div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
