import states from "./states.json";

export const getFullStateName = (stateCode) => {
  return states?.find((state) => state?.abbreviation === stateCode)?.name;
};

export const getStateCoordinates = (stateCode) => {
  const state = states?.find((state) => state?.abbreviation === stateCode);
  return state
    ? { lat: state?.coordinates?.lat, lng: state?.coordinates?.lng }
    : null;
};
