import * as type from "../types";

const equipmentBrandReducer = (equipmentBrands = [], action) => {
  switch (action.type) {
    case type.CREATE_EQUIPMENTBRAND:
      return [action.payload, ...equipmentBrands];
    case type.GET_EQUIPMENTBRANDS:
      return action.payload.reverse();
    case type.DELETE_EQUIPMENTBRAND:
      return equipmentBrands.filter(
        (equipmentBrand) => equipmentBrand._id !== action.payload
      );
    case type.UPDATE_EQUIPMENTBRAND:
      return equipmentBrands.map((equipmentBrand) =>
        equipmentBrand._id === action.payload._id
          ? action.payload
          : equipmentBrand
      );
    default:
      return equipmentBrands;
  }
};

export default equipmentBrandReducer;
