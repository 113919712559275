import * as type from "../types";

const optionSetReducer = (optionSets = [], action) => {
  switch (action.type) {
    case type.CREATE_OPTIONSET:
      return [action.payload, ...optionSets];
    case type.GET_OPTIONSETS:
      return action.payload.reverse();
    case type.DELETE_OPTIONSET:
      return optionSets.filter((optionSet) => optionSet._id !== action.payload);
    case type.UPDATE_OPTIONSET:
      return optionSets.map((optionSet) =>
        optionSet._id === action.payload._id ? action.payload : optionSet
      );
    default:
      return optionSets;
  }
};

export default optionSetReducer;
