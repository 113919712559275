import React, { useEffect, useRef, useState } from "react";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import herographics from "../../assets/pm consumer/image.png";
import greenGraphics from "../../assets/pm consumer/image (1).png";
import heroImage from "../../assets/pm consumer/image (2).png";
import map2Img from "../../assets/pm consumer/Map2.png";
import work1 from "../../assets/pm consumer/work1.png";
import work2 from "../../assets/pm consumer/work2.png";
import work3 from "../../assets/pm consumer/work3.png";
import work4 from "../../assets/pm consumer/work4.png";
import tabImage from "../../assets/pm consumer/image (3).png";
import bgGreenImage from "../../assets/pm consumer/tilt bg.png";
import bgVideo from "../../assets/pm consumer/Rectangle 28.png";
import videoFile from "../../assets/pm consumer/Explainer video 828.mp4";
import embed1 from "../../assets/pm consumer/pm1.png";
import embed2 from "../../assets/pm consumer/pm2.png";
import embed3 from "../../assets/pm consumer/pm3.png";
import embed4 from "../../assets/pm consumer/pm4.png";
import protect1 from "../../assets/pm consumer/pro1.png";
import protect2 from "../../assets/pm consumer/pro2.png";
import protect3 from "../../assets/pm consumer/pro3.png";
import quotes from "../../assets/pm consumer/Group.png";
import userIcon from "../../assets/pm consumer/users icon.png";
import mapIcon from "../../assets/pm consumer/map icon.png";
import circle from "../../assets/pm consumer/Ellipse.png";
import cross from "../../assets/pm consumer/cross.png";
import dots from "../../assets/pm consumer/dots.png";
import sideIcon from "../../assets/pm consumer/side.png";
import { FaStar } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";
import Slider from "react-slick";
import { IoIosPlayCircle } from "react-icons/io";

const reviews = [
  {
    id: 1,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 2,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
  {
    id: 3,
    stars: 5,
    text: "Pro-Mapper is easy to use and provides my potential clients with an insight into the quality of work we provide.",
    name: "Justin",
    location: "Ohio",
  },
  {
    id: 4,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 5,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing! My customers love seeing my local projects. Pro-Mapper is amazing! My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
];
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function Home() {
  const iframeRef = React.useRef();
  const [iframeHeight, setIframeHeight] = React.useState("0px");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const onIfarmeLoad = () => {
    // console.log(iframeRef.current.contentWindow);
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDocument =
        iframeRef.contentDocument || iframe.contentWindow.document;
      setIframeHeight(`${iframeDocument.documentElement.scrollHeight}px`);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    // Add event listeners for play and pause
    video?.addEventListener("play", handlePlay);
    video?.addEventListener("pause", handlePause);

    // Cleanup event listeners on component unmount
    return () => {
      video?.removeEventListener("play", handlePlay);
      video?.removeEventListener("pause", handlePause);
    };
  }, []);
  return (
    <div className="items-center">
      <div className=" mx-auto max-w-[1900px]">
        <Navigation />
        <hr className="mb-0" />
        <div className="relative md:px-20 lg:px-20 px-5">
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute top-5 left-10"
            src={cross}
            alt="graphics"
          />
          <img
            className="h-3 w-auto z-20 absolute top-52 md:bottom-20 lg:bottom-20 left-3 md:left-14 lg:left-14"
            src={circle}
            alt="graphics"
          />
          <img
            className="h-[21rem] md:h-[35rem] lg:h-[35rem] -z-20 absolute top-0 right-0"
            src={herographics}
            alt="graphics"
          />
          <div className="max-w-7xl mx-auto grid grid-cols-12 py-10">
            <div className="my-auto lg:col-span-6 md:col-span-12 col-span-12 md:p-8 p-4 lg:p-8 order-2 lg:order-1">
              <h2 className="uppercase pt-1 lg:text-4xl md:text-4xl text-2xl text-black font-bold">
                Showcase your <br /> Projects, Sell More Deals
              </h2>
              <ul className="pt-4 list-none">
                <li className="flex items-start">
                  <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-green-600 text-xl mt-0.5 " />
                  <span className="text-lg font-light text-gray-700">
                    98% of people said seeing other projects in their
                    neighborhood is beneficial.
                  </span>
                </li>
                <li className="flex items-start ">
                  <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-green-600 text-xl mt-0.5 " />
                  <span className="text-lg font-light text-gray-700">
                    83% of people prefer businesses that have worked locally.
                  </span>
                </li>
                <li className="flex items-start ">
                  <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-green-600 text-xl mt-0.5 " />
                  <span className="text-lg font-light text-gray-700">
                    Proven to boost sales and client acquisition.
                  </span>
                </li>
              </ul>
              <div className="pt-4">
                <div className="flex gap-2 md:gap-4 lg:gap-4 items-start justify-start">
                  <div className="mt-2 md:mt-3 lg:mt-3 text-center">
                    <Link
                      to={`${process.env.REACT_APP_PM_APP_URL}/pricing?type=business`}
                      className="rounded-full lg:px-10 md:px-10 px-5 py-4 font-bold text-white lg:text-base md:text-base text-xs bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
                    >
                      Plans & Pricing
                    </Link>
                  </div>
                  <PopupButton
                    url="https://calendly.com/pro-mapper-info/30min"
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                    text="Book a Demo"
                    className="rounded-full lg:px-10 md:px-10 px-5 py-3 font-bold text-[#0BA852] lg:text-base md:text-base text-xs bg-white border border-[#0BA852]"
                    pageSettings={{
                      backgroundColor: "ffffff",
                      hideGdprBanner: true,
                      hideLandingPageDetails: true,
                      primaryColor: "0da853",
                      textColor: "0da853",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="relative lg:col-span-6 md:col-span-12 col-span-12 order-1 lg:order-2">
              <img
                className="lg:block md:block hidden h-3 w-auto z-20 absolute top-5 left-16"
                src={circle}
                alt="graphics"
              />
              <img
                className="lg:block md:block hidden h-3 w-auto z-20 absolute -bottom-16 -left-14"
                src={cross}
                alt="graphics"
              />
              <div className="relative">
                <img
                  className="h-auto md:h-[25rem] lg:h-[25rem] object-contain w-full"
                  src={heroImage}
                  alt="Hero"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Embed Our Map and Boost Your Marketing! */}
        <div
          className="max-w-7xl mx-auto py-3 lg:my-16 md:my-16 my-2 py-2  md:py-10 lg:py-10"
          id="boost-your-marketing"
        >
          <h2 className="uppercase text-2xl md:text-4xl lg:text-4xl text-center font-bold mb-4">
            Embed Our Map and Boost Your Marketing!
          </h2>

          <div className="p-4 py-10 gap-5 grid grid-cols-12">
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div
                className="rounded-lg bg-gradient-to-tl from-[#A2D0B7] via-transparent to-transparent p-[1px] h-full"
                style={{
                  boxShadow: "0px 4px 34px 0px rgba(201, 212, 206, 0.3)",
                }}
              >
                <div className="py-2 lg:py-5 px-3 w-full rounded-lg relative bg-white h-full ">
                  <div className="flex flex-col items-center px-5 ">
                    <div className="py-2 bg-green-600 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-3.5"
                        src={embed1}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Increase CTR
                    </h5>
                    <p className="text-center text-base text-gray-500">
                      Attract and engage more visitors by using interactive maps
                      that showcase your projects, encouraging users to explore
                      further.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div
                className="rounded-lg bg-gradient-to-tl from-[#A2D0B7] via-transparent to-transparent p-[1px] h-full"
                style={{
                  boxShadow: "0px 4px 34px 0px rgba(201, 212, 206, 0.3)",
                }}
              >
                <div className="py-2 lg:py-5 px-3 w-full rounded-lg relative bg-white h-full ">
                  <div className="flex flex-col items-center px-5 ">
                    <div className="py-2 bg-green-600 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-3.5"
                        src={embed2}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Lower CPC
                    </h5>
                    <p className="text-center text-base text-gray-500">
                      Optimize your marketing budget by lowering cost per click.
                      Projcet maps attract more engaged users, reducing wasteful
                      spending.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div
                className="rounded-lg bg-gradient-to-tl from-[#A2D0B7] via-transparent to-transparent p-[1px] h-full"
                style={{
                  boxShadow: "0px 4px 34px 0px rgba(201, 212, 206, 0.3)",
                }}
              >
                <div className="py-2 lg:py-5 px-3 w-full border border-gray-200 rounded-lg relative bg-white h-full ">
                  <div className="flex flex-col items-center px-5 ">
                    <div className="py-2 bg-green-600 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-3.5"
                        src={embed3}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Improve SEO
                    </h5>
                    <p className="text-center text-base text-gray-500">
                      Enhance your website's visibility by integrating dynamic
                      map content, making your site more appealing to search
                      engines and users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div
                className="rounded-lg bg-gradient-to-tl from-[#A2D0B7] via-transparent to-transparent p-[1px] h-full"
                style={{
                  boxShadow: "0px 4px 34px 0px rgba(201, 212, 206, 0.3)",
                }}
              >
                <div className="py-2 lg:py-5 px-3 w-full border border-gray-200 rounded-lg relative bg-white h-full ">
                  <div className="flex flex-col items-center px-5 ">
                    <div className="py-2 bg-green-600 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-3.5"
                        src={embed4}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Deepen Engagement
                    </h5>
                    <p className="text-center text-base text-gray-500">
                      Encourage further engagement with visitors by displaying
                      projects in their area, enticing them to reach out and
                      connect with your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            <Link
              to={`${process.env.REACT_APP_PM_APP_URL}/pricing?type=business`}
              className="rounded-full lg:px-10 md:px-10 px-5 py-4 font-bold text-white lg:text-base md:text-base text-xs bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
            >
              Plans & Pricing
            </Link>
          </div>
        </div>
        {/* Video section  */}
        {/* w-full h-[30.3rem] object-contain lg:block md:block hidden absolute -left-2.5 top-[6.9rem] -z-10  */}
        <div
          className="relative max-w-7xl mx-auto py-3 md:py-10 lg:py-10"
          id="explainer-video"
        >
          <h2 className="lg:px-0 md:px-0 px-4 mb-10 uppercase text-2xl md:text-4xl lg:text-4xl text-center font-bold">
            Discover How our innovative maps elevate your business
          </h2>
          <img
            className="w-full h-[30.3rem] object-contain lg:block md:block hidden absolute -left-2.5 top-[6.9rem] -z-10 "
            src={bgVideo}
            alt="Bonnie"
          />
          <div className="max-w-[80%] mx-auto relative rounded-lg">
            {!isPlaying && (
              <div
                className="w-[98%] absolute inset-0 flex justify-center items-center bg-black rounded-md bg-opacity-50 cursor-pointer"
                onClick={handlePlayPause}
              >
                <div className="flex items-center justify-center h-24 w-24 rounded-full bg-green-600 bg-opacity-40">
                  <IoIosPlayCircle className="text-white text-7xl" />
                </div>
              </div>
            )}
            <video
              ref={videoRef}
              className="w-[98%] h-48 md:h-[29rem] lg:h-[29rem] object-cover rounded-lg"
              controls
              muted
            >
              <source src={videoFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        {/* Green background section  */}
        <div className="lg:bg-transparent md:bg-transparent bg-green-600 relative py-3 md:py-10 lg:py-10">
          <img
            className="w-full h-full object-contain lg:block md:block hidden absolute bottom-10 -z-10 "
            src={bgGreenImage}
            alt="Bonnie"
          />
          <div className="md:p-0 p-4 lg:p-0 max-w-7xl mx-auto py-5 md:py-20 lg:py-20 ">
            <div className="grid grid-cols-12 py-5">
              <div className="my-auto lg:col-span-6 md:col-span-12 col-span-12 order-1 lg:order-1">
                <div className="">
                  <img
                    className="h-auto md:h-[25rem] lg:h-[25rem] object-contain w-full"
                    src={map2Img}
                    alt="Hero"
                  />
                </div>
              </div>
              <div className="my-auto lg:col-span-6 md:col-span-12 col-span-12 md:p-8 py-4 lg:py-8 order-2 lg:order-2">
                <h2 className="text-white uppercase pt-1 lg:text-4xl md:text-4xl text-2xl text-black font-bold">
                  Why Choose Pro-Mapper for Your Business
                </h2>
                <ul className="pt-5 text-white list-none">
                  <li className="flex items-start">
                    <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-[#FFB800] text-xl mt-0.5 " />
                    <span className="text-lg font-light text-gray-100">
                      <span className="font-bold">Easy Setup:</span> Get started
                      in minutes with our intuitive profile setup process.
                    </span>
                  </li>
                  <li className="flex items-start ">
                    <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-[#FFB800] text-xl mt-0.5 " />
                    <span className="text-lg font-light text-gray-100">
                      <span className="font-bold">Dynamic Mapping:</span>
                      Showcase your local projects on dynamic, interactive maps
                      to lower marketing costs
                    </span>
                  </li>
                  <li className="flex items-start ">
                    <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-[#FFB800] text-xl mt-0.5 " />
                    <span className="text-lg font-light text-gray-100">
                      <span className="font-bold">Boost Credibility:</span>{" "}
                      Display completed projects to build trust and credibility
                      with new clients.
                    </span>
                  </li>
                  <li className="flex items-start ">
                    <FaAngleDoubleRight className="flex-shrink-0 mr-3 text-[#FFB800] text-xl mt-0.5 " />
                    <span className="text-lg font-light text-gray-100">
                      <span className="font-bold">Increased Visibility: </span>{" "}
                      Make your projects visible to a wider audience and attract
                      more business.
                    </span>
                  </li>
                </ul>
                <div className="pt-4">
                  <div className="flex gap-2 md:gap-4 lg:gap-4 items-start justify-start">
                    <div className="mt-2 md:mt-3 lg:mt-3 text-center">
                      <Link
                        to={`${process.env.REACT_APP_PM_APP_URL}/pricing?type=business`}
                        className="rounded-full lg:px-10 md:px-10 px-5 py-4 font-bold text-white lg:text-base md:text-base text-xs bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
                      >
                        Sign Up for Free
                      </Link>
                      <p className="text-xs md:text-sm lg:text-sm text-gray-100 mt-5">
                        No credit card required
                      </p>
                    </div>
                    <PopupButton
                      url="https://calendly.com/pro-mapper-info/30min"
                      rootElement={document.getElementById("root")}
                      text="Book a Demo"
                      className="rounded-full lg:px-10 md:px-10 px-5 py-3 font-bold text-[#0BA852] lg:text-base md:text-base text-xs bg-white border border-[#0BA852]"
                      pageSettings={{
                        backgroundColor: "ffffff",
                        hideGdprBanner: true,
                        hideLandingPageDetails: true,
                        primaryColor: "0da853",
                        textColor: "0da853",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* How it works  */}
        <div
          className="mx-auto max-w-7xl py-3 md:py-10 lg:py-10"
          id="how-it-works"
        >
          <h2 className="uppercase text-3xl md:text-4xl lg:text-4xl text-center font-bold mb-3">
            HOW IT WORKS
          </h2>
          <p className="mb-7 mx-auto md:w-3/5 w-full lg:w-3/5 text-gray-500  text-sm leading-relaxed text-center">
            Effortlessly Display Your Projects and Increase Client Engagement in
            Four Easy Steps.
          </p>

          <div className="md:p-0 p-4 lg:p-0 py-10 gap-6 grid grid-cols-12">
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div className="relative">
                <div className="absolute -top-3 left-10">
                  <span className="bg-green-600 text-white h-7 w-7 flex items-center justify-center rounded-full">
                    1
                  </span>
                </div>
                <div className="flex flex-col items-center ">
                  <img
                    className="border border-[#D7D7D7] rounded-md h-40 w-full object-cover"
                    src={work4}
                    alt="Bonnie"
                  />
                </div>
                <div className="px-4 py-4">
                  <h5 className="text-xl font-bold text-gray-900 mb-3">
                    Set Up Your Profile
                  </h5>
                  <p className="text-base text-gray-500">
                    Create your profile by adding a logo, selecting your
                    services and project types.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div className="relative">
                <div className="absolute -top-3 left-10">
                  <span className="bg-green-600 text-white h-7 w-7 flex items-center justify-center rounded-full">
                    2
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    className="border border-[#D7D7D7] rounded-md h-40 w-full object-cover"
                    src={work1}
                    alt="Bonnie"
                  />
                </div>
                <div className="px-4 py-4">
                  <h5 className="text-xl font-bold text-gray-900 mb-3">
                    Add Your Projects
                  </h5>
                  <p className="text-base text-gray-500">
                    Easily add past project details and images to showcase your
                    work.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div className="relative">
                <div className="absolute -top-3 left-10">
                  <span className="bg-green-600 text-white h-7 w-7 flex items-center justify-center rounded-full">
                    3
                  </span>
                </div>
                <div className="flex flex-col items-center ">
                  <img
                    className="border border-[#D7D7D7] rounded-md h-40 w-full object-cover"
                    src={work2}
                    alt="Bonnie"
                  />
                </div>
                <div className="px-4 py-4">
                  <h5 className="text-xl font-bold text-gray-900 mb-3">
                    Create your Maps
                  </h5>
                  <p className="text-base text-gray-500">
                    Customize and save maps with preset filters and locations to
                    display relevant projects.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <div className="relative">
                <div className="absolute -top-3 left-10">
                  <span className="bg-green-600 text-white h-7 w-7 flex items-center justify-center rounded-full">
                    4
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    className="border border-[#D7D7D7] rounded-md h-40 w-full object-cover"
                    src={work3}
                    alt="Bonnie"
                  />
                </div>
                <div className="px-4 py-4">
                  <h5 className="text-xl font-bold text-gray-900 mb-3">
                    Embed & Share Custom Maps
                  </h5>{" "}
                  <p className="text-base text-gray-500">
                    Easily embed customized maps to showcase your work in local
                    neighbourhoods.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Experience Pro-Mapper in Action */}
        <div className="relative" id="see-pro-mapper-in-action">
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute bottom-16 left-44"
            src={circle}
            alt="graphics"
          />
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute bottom-24 right-52"
            src={cross}
            alt="graphics"
          />
          <div className="max-w-7xl mx-auto py-3 md:py-10 lg:py-10">
            <div className="my-5 flex items-center justify-center">
              <div>
                <h2 className="text-2xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-4">
                  Experience Pro-Mapper in Action
                </h2>
              </div>
            </div>
            <div className="lg:px-0 md:px-0 px-4 relative w-full md:max-w-[80%] lg:max-w-[80%] mx-auto">
              <img
                className="lg:block md:block hidden absolute -bottom-12 -left-10 -z-10 p-4"
                src={dots}
                alt="Bonnie"
              />
              <img
                className="lg:block md:block hidden absolute top-12 -right-16 -z-10 p-4"
                src={sideIcon}
                alt="Bonnie"
              />
              <iframe
                ref={iframeRef}
                onLoad={onIfarmeLoad}
                className="w-full h-[50rem]"
                width="100%"
                src="https://staging.pro-mapper.com/company/66b155793dd0ca11308fdece/projects?filter=66e1c1817abecb3224a5f07d"
              ></iframe>
            </div>
            <div className="pt-4">
              <div className="mt-10 text-center">
                <Link
                  to={`${process.env.REACT_APP_PM_APP_URL}/pricing?type=business`}
                  className="rounded-full px-10 py-4 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
                >
                  Sign Up for Free
                </Link>
                <p className="text-sm text-gray-600 mt-5">
                  No credit card required
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Protect Your Project Privacy */}
        <div className="py-8 bg-[#F5FBF8] relative">
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute bottom-28 left-24"
            src={cross}
            alt="graphics"
          />
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute top-3 right-28"
            src={circle}
            alt="graphics"
          />
          <div className="max-w-7xl mx-auto py-3 md:py-10 lg:py-10">
            <h2 className="uppercase text-2xl md:text-4xl lg:text-4xl text-center font-bold mb-3">
              Protect Your Project Privacy
            </h2>
            <p className="mb-7 px-4 mx-auto md:w-3/5 w-full lg:w-3/5 text-gray-500  text-sm leading-relaxed text-center">
              Ensure Privacy and Control: Manage Your Project Visibility with
              Ease
            </p>

            <div className="pt-3 gap-5 grid grid-cols-12">
              <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="">
                  <div className="py-10 flex flex-col items-center ">
                    <div className="bg-[#D8EFE3] bg-opacity-70 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-4"
                        src={protect1}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Privacy Protection
                    </h5>
                    <p className="px-3 text-center text-sm text-gray-500">
                      Keep your clients' information secure by hiding specific
                      project addresses. Pro-Mapper ensures your project data
                      remains private and secure.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="">
                  <div className="py-10 flex flex-col items-center px-5 ">
                    <div className="bg-[#D8EFE3] bg-opacity-70 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-4"
                        src={protect2}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Confidential Display
                    </h5>
                    <p className="px-3 text-center text-sm text-gray-500">
                      Showcase your work without revealing sensitive details.
                      Our platform allows you to display your projects
                      confidently while maintaining confidentiality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="">
                  <div className="py-10 flex flex-col items-center px-5 ">
                    <div className="bg-[#D8EFE3] bg-opacity-70 mb-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                      <img
                        className="h-20 object-cover p-4"
                        src={protect3}
                        alt="Bonnie"
                      />
                    </div>
                    <h5 className="text-center mb-1 text-xl font-bold text-gray-800">
                      Control and Customize
                    </h5>
                    <p className="px-3 text-center text-sm text-gray-500">
                      Have full control over what information is displayed.
                      Customize your map to showcase only the necessary details.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* OUR GROWING COMMUNITY */}

        <div className="md:px-20 px-5 lg:px-20 py-3 md:py-5 lg:py-5">
          <div className="my-5 flex items-center justify-center">
            <div className="w-full md:w-3/5 lg:w-3/5">
              <h2 className="text-2xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-2">
                Our Growing Community
              </h2>
              <p className="mx-auto md:w-3/5 w-full lg:w-3/5 text-gray-500  text-sm leading-relaxed text-center">
                Join hundreds of users showcasing thousands of projects with
                Pro-Mapper.
              </p>
              <div className="relative my-5 gap-4 flex items-center justify-center">
                <img
                  className="h-3 w-auto z-20 absolute top-5 left-3 md:-left-16 lg:-left-16"
                  src={circle}
                  alt="graphics"
                />
                <img
                  className="lg:block md:block hidden h-3 w-auto z-20 absolute bottom-0 left-14"
                  src={cross}
                  alt="graphics"
                />
                <img
                  className="h-3 w-auto z-20 absolute bottom-0 lg:-right-16 md:-right-16 right-3"
                  src={circle}
                  alt="graphics"
                />
                <img
                  className="lg:block md:block hidden h-3 w-auto z-20 absolute top-0 right-14"
                  src={cross}
                  alt="graphics"
                />
                <div className="mt-5 relative h-40 w-52 flex items-center justify-center rounded-md py-2 bg-[#F4FBF7] border-b border-[#A2D0B7]">
                  <div className="px-5">
                    <div className="absolute -top-7 left-1/2 transform -translate-x-1/2 bg-[#D8EFE3] mb-3 h-16 w-16 flex items-center justify-center rounded-full border-2 border-white">
                      <img
                        className="h-10 object-cover text-green-600"
                        src={userIcon}
                        alt="Bonnie"
                      />
                    </div>
                    <h1 className="text-center text-green-600 text-6xl">277</h1>
                    <p className="text-sm text-center text-gray-700">Users</p>
                  </div>
                </div>
                <div className="mt-5 relative h-40 w-52 flex items-center justify-center rounded-md py-2 bg-[#F4FBF7] border-b border-[#A2D0B7]">
                  <div className="px-5">
                    <div className="absolute -top-7 left-1/2 transform -translate-x-1/2 bg-[#D8EFE3] mb-3 h-16 w-16 flex items-center justify-center rounded-full border-2 border-white">
                      <img
                        className="h-10 object-cover text-green-600"
                        src={mapIcon}
                        alt="Bonnie"
                      />
                    </div>
                    <h1 className="text-center text-green-600 text-6xl">
                      13524
                    </h1>
                    <p className="text-sm text-center text-gray-700">
                      Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* WHAT OUR USERS ARE SAYING */}
        <div
          className="max-w-7xl mx-auto py-3 md:py-5 lg:py-10"
          id="testimonials"
        >
          <h2 className="text-3xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-3">
            What our users are saying
          </h2>
          <p className="mb-7 mx-auto w-full text-gray-500  text-sm leading-relaxed text-center">
            Hear from our satisfied clients who have experienced remarkable
            results with Pro-Mapper.
          </p>

          <div className="overflow-hidden mt-5 slider-container">
            <Slider {...settings}>
              {reviews.map((review) => (
                <div key={review.id} className="flex justify-center">
                  <div className="mx-4 lg:col-span-4 md:col-span-6 col-span-12 h-full">
                    <div
                      className="rounded-lg bg-gradient-to-tl from-green-600 via-transparent to-transparent p-[1px] h-full"
                      style={{
                        boxShadow: " 0px 4px 34px 0px #0000000A",
                      }}
                    >
                      <div
                        className="py-2 lg:py-5 px-4 w-full rounded-lg relative bg-white h-full grid grid-rows-[auto,1fr,auto]"
                        style={{
                          boxShadow: " 0px 4px 34px 0px #0000000A",
                        }}
                      >
                        <div className="absolute right-0 bottom-0">
                          <img
                            className="w-full object-cover p-4"
                            src={quotes}
                            alt={review.name}
                          />
                        </div>
                        <div className="flex flex-col px-4">
                          <div className="flex mt-2">
                            {Array.from({ length: review.stars }).map(
                              (_, index) => (
                                <FaStar
                                  key={index}
                                  className="h-5 w-5 text-yellow-500"
                                />
                              )
                            )}
                          </div>
                        </div>
                        <div className="px-4 py-4 h-36 flex-grow">
                          <p className="text-base text-gray-700">
                            {review.text}
                          </p>
                        </div>
                        <div className="px-4 pt-4 relative">
                          <h5 className="text-lg font-bold text-gray-900">
                            {review.name}
                          </h5>
                          <p className="text-base text-gray-700">
                            {review.location}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/*  About us section  */}
        <div className="relative my-14">
          <img
            className="h-[22rem] md:h-[31rem] lg:h-[31rem] -z-20 absolute -top-10 left-0"
            src={greenGraphics}
            alt="graphics"
          />
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute bottom-28 right-32"
            src={cross}
            alt="graphics"
          />
          <img
            className="lg:block md:block hidden h-3 w-auto z-20 absolute -bottom-10 right-52"
            src={circle}
            alt="graphics"
          />
          <div className="mx-auto max-w-7xl my-5 grid grid-cols-12 items-center">
            <div className="relative lg:col-span-6 md:col-span-12 col-span-12 ">
              <div className="">
                <img
                  src={tabImage}
                  alt="About Us"
                  className="mx-auto h-auto md:h-[25rem] lg:h-[25rem] object-contain w-full"
                />
              </div>
            </div>
            <div className="mt-10 lg:mt-2 lg:col-span-6 md:col-span-12 col-span-12 p-3 ">
              <div className="px-3 my-auto">
                <p className="uppercase text-2xl md:text-4xl lg:text-4xl mb-4 font-bold">
                  Transform Your Business with Pro-Mapper
                </p>

                <p className="text-lg text-gray-500 mb-4">
                  Take the next step in showcasing your projects and enhancing
                  client engagement. Pro-Mapper is designed to help you boost
                  your business visibility effortlessly..
                </p>
                <div className="pt-6">
                  <div className="flex gap-2 md:gap-4 lg:gap-4 items-start justify-start">
                    <div className="mt-2 md:mt-3 lg:mt-3 text-center">
                      <Link
                        to={`${process.env.REACT_APP_PM_APP_URL}/pricing?type=business`}
                        className="rounded-full lg:px-10 md:px-10 px-5 py-4 font-bold text-white lg:text-base md:text-base text-xs bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
                      >
                        Plans & Pricing
                      </Link>
                    </div>
                    <PopupButton
                      url="https://calendly.com/pro-mapper-info/30min"
                      /*
                       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                       */
                      rootElement={document.getElementById("root")}
                      text="Book a Demo"
                      className="rounded-full lg:px-10 md:px-10 px-5 py-3 font-bold text-[#0BA852] lg:text-base md:text-base text-xs bg-white border border-[#0BA852]"
                      pageSettings={{
                        backgroundColor: "ffffff",
                        hideGdprBanner: true,
                        hideLandingPageDetails: true,
                        primaryColor: "0da853",
                        textColor: "0da853",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
