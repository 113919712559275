import React, { useEffect, useState } from "react";
import { getRoofTypes } from "../../../redux/api";

const RoofType = ({
  formData,
  setFormData,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
}) => {
  const [roofTypes, setRoofTypes] = useState([]);

  useEffect(() => {
    getRoofTypes()
      .then((res) => {
        setRoofTypes(res?.data?.options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setIsNextDisabled(!formData?.roofType);
  }, [formData?.roofType, setIsNextDisabled]);
  return (
    <>
      <div
        className="grid grid-cols-2 gap-3"
        role="group"
        aria-labelledby="my-radio-group"
      >
        {roofTypes?.map((roofType, index) => (
          <div key={index} className="bg-slate-100 rounded-lg p-3">
            <label className="block text-sm text-gray-700">
              <input
                className="appearance-none w-4 h-4 border border-gray-400 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none"
                type="radio"
                name="roofType"
                value={roofType}
                onChange={handleChange}
                checked={formData?.roofType === roofType}
              />
              <span className="ms-2">{roofType}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default RoofType;
