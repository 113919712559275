import * as type from "../types";

const equipmentModelReducer = (equipmentModels = [], action) => {
  switch (action.type) {
    case type.CREATE_EQUIPMENTMODEL:
      return [action.payload, ...equipmentModels];
    case type.GET_EQUIPMENTMODELS:
      return action.payload.reverse();
    case type.DELETE_EQUIPMENTMODEL:
      return equipmentModels.filter(
        (equipmentModel) => equipmentModel._id !== action.payload
      );
    case type.UPDATE_EQUIPMENTMODEL:
      return equipmentModels.map((equipmentModel) =>
        equipmentModel._id === action.payload._id
          ? action.payload
          : equipmentModel
      );
    default:
      return equipmentModels;
  }
};

export default equipmentModelReducer;
