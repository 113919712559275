import React, { useEffect } from "react";
import { isEmailValid } from "../../../utils/regexValidations";

const Email = ({
  formData,
  setFormData,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
}) => {
  useEffect(() => {
    if (formData?.email && isEmailValid(formData?.email))
      setIsNextDisabled(false);
    else setIsNextDisabled(true);
  }, [formData?.email, setIsNextDisabled]);
  return (
    <>
      <div className="h-full w-full flex justify-start items-center">
        <input
          id="email"
          name="email"
          type="email"
          value={formData?.email}
          onChange={handleChange}
          placeholder="Enter your email"
          className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
        />
      </div>
    </>
  );
};

export default Email;
