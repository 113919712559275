import authReducer from "./auth";
import serviceReducer from "./service";
import equipmentTypeReducer from "./equipmentType";
import equipmentModelReducer from "./equipmentModel";
import equipmentBrandReducer from "./equipmentBrand";
import { combineReducers } from "redux";
import workTypeReducer from "./workType";
import companyReducer from "./company";
import userReducer from "./user";
import contactReducer from "./contact";
import officeReducer from "./office";
import projectReducer from "./project";
import optionSetReducer from "./optionSet";
import roleReducer from "./role";

const rootReducer = combineReducers({
  authReducer,
  serviceReducer,
  equipmentTypeReducer,
  equipmentModelReducer,
  equipmentBrandReducer,
  workTypeReducer,
  companyReducer,
  userReducer,
  contactReducer,
  officeReducer,
  projectReducer,
  optionSetReducer,
  roleReducer,
});

export default rootReducer;
