import Swal from "sweetalert2";

export const success = (message) => {
  Swal.fire({
    title: `${message}!`,
    confirmButtonColor: "#2faf4d",
    text: `Your data has been ${message.toLowerCase()}`,
    icon: "success",
  });
};

export const loginSuccess = () => {
  Swal.fire({
    title: `Success!`,
    confirmButtonColor: "#2faf4d",
    text: `Login Successfully`,
    icon: "success",
  });
};

export const warning = (message) => {
  Swal.fire({
    title: "Warning!",
    confirmButtonColor: "#2faf4d",
    text: message,
    icon: "warning",
  });
};

export const failure = (message) => {
  Swal.fire({
    title: "Error!",
    confirmButtonColor: "#2faf4d",
    text: message,
    icon: "error",
  });
};

export const confirmation = () =>
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to delete this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#2faf4d",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
  });
