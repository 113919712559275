import * as type from "../types";

const projectReducer = (projects = [], action) => {
  switch (action.type) {
    case type.CREATE_PROJECT:
      return [...projects];
    case type.GET_PROJECTS:
      return action.payload.reverse();
    case type.DELETE_PROJECT:
      return projects.filter((project) => project._id !== action.payload);
    case type.UPDATE_PROJECT:
      return projects.map((project) =>
        project._id === action.payload._id ? action.payload : project
      );
    default:
      return projects;
  }
};

export default projectReducer;
