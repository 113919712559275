import React, { useEffect } from "react";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";

const NoServiceInZip = ({
  steps,
  company,
  formData,
  setFormData,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
}) => {
  return <div dangerouslySetInnerHTML={{ __html: steps[9].message }} />;
};

export default NoServiceInZip;
