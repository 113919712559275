import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {
  baseURL,
  getBrandsOfferedByCompany,
  getCompanyById,
  getLandingPageById,
  getMapPageById,
  getRelevantProjects,
  getServicesByCompanyId,
  storePartnersPageResopnseInDatabase,
  updateContactAndZipStatus,
} from "../../../redux/api";
import Slider from "react-slick";
import Modal from "../../../components/Modal/Modal";
import ActiveStep from "../components/ActiveStep";
import RoofArea from "../../../assets/images/RoofArea.jpg";
import MapHeader from "../../../assets/images/MapHeader.jpg";
import People1 from "../../../assets/images/People1.jpg";
import People2 from "../../../assets/images/People2.jpg";
import People3 from "../../../assets/images/People3.jpg";
import RoofingSectionBackground from "../../../assets/images/RoofingSectionBackground.jpg";
import heroMap from "../../../assets/images/roofingLandingPageHeroMap.png";
import heroImg from "../../../assets/images/roofingLandingPageHeroImg.png";
import sectionImg1 from "../../../assets/images/roofingLandingPageSectionImg1.png";
import sectionImg2 from "../../../assets/images/roofingLandingPageSectionImg2.png";
import sectionImg3 from "../../../assets/images/roofingLandingPageSectionImg3.png";
import sectionImg4 from "../../../assets/images/roofingLandingPageSectionImg4.png";
import logo from "../../../assets/images/logo.png";
import { FaCircleArrowRight } from "react-icons/fa6";
import { PiMedalFill } from "react-icons/pi";
import { FaRankingStar } from "react-icons/fa6";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import RoofingResults from "../components/RoofingResults";
import { getFullStateName, getStateCoordinates } from "../../../utils/states";
import { formatNumber } from "../../../utils/numbers";
import { isValidZipCode } from "../../../utils/regexValidations";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const roofData = [
  {
    systemSizeInkW: "3",
    avgCost: "$6900 - $12000",
    costAfterTaxCredit: "$4830 - $8400",
  },
  {
    systemSizeInkW: "3.5",
    avgCost: "$8050 - $14000",
    costAfterTaxCredit: "$5635 - $9800",
  },
  {
    systemSizeInkW: "4",
    avgCost: "$9200 - $16000",
    costAfterTaxCredit: "$6440 - $11200",
  },
  {
    systemSizeInkW: "4.5",
    avgCost: "$10350 - $18000",
    costAfterTaxCredit: "$7245 - $12600",
  },
  {
    systemSizeInkW: "5",
    avgCost: "$11500 - $20000",
    costAfterTaxCredit: "$8050 - $14000",
  },
  {
    systemSizeInkW: "5.5",
    avgCost: "$12650 - $22000",
    costAfterTaxCredit: "$8855 - $15400",
  },
  {
    systemSizeInkW: "6",
    avgCost: "$13800 - $24000",
    costAfterTaxCredit: "$9660 - $16800",
  },
  {
    systemSizeInkW: "6.5",
    avgCost: "$14950 - $26000",
    costAfterTaxCredit: "$10465 - $18200",
  },
  {
    systemSizeInkW: "7",
    avgCost: "$16100 - $28000",
    costAfterTaxCredit: "$11270 - $19600",
  },
  {
    systemSizeInkW: "7.5",
    avgCost: "$17250 - $30000",
    costAfterTaxCredit: "$12075 - $21000",
  },
  {
    systemSizeInkW: "8",
    avgCost: "$18400 - $32000",
    costAfterTaxCredit: "$12880 - $22400",
  },
  {
    systemSizeInkW: "8.5",
    avgCost: "$19550 - $34000",
    costAfterTaxCredit: "$13685 - $23800",
  },
  {
    systemSizeInkW: "9",
    avgCost: "$20700 - $36000",
    costAfterTaxCredit: "$14490 - $25200",
  },
  {
    systemSizeInkW: "9.5",
    avgCost: "$21850 - $38000",
    costAfterTaxCredit: "$15295 - $26600",
  },
  {
    systemSizeInkW: "10",
    avgCost: "$23000 - $40000",
    costAfterTaxCredit: "$16100 - $28000",
  },
  {
    systemSizeInkW: "10.5",
    avgCost: "$24150 - $42000",
    costAfterTaxCredit: "$16905 - $29400",
  },
  {
    systemSizeInkW: "11",
    avgCost: "$25300 - $44000",
    costAfterTaxCredit: "$17710 - $30800",
  },
  {
    systemSizeInkW: "11.5",
    avgCost: "$26450 - $46000",
    costAfterTaxCredit: "$18515 - $32200",
  },
  {
    systemSizeInkW: "12",
    avgCost: "$27600 - $48000",
    costAfterTaxCredit: "$19320 - $33600",
  },
  {
    systemSizeInkW: "12.5",
    avgCost: "$28750 - $50000",
    costAfterTaxCredit: "$20125 - $35000",
  },
  {
    systemSizeInkW: "13",
    avgCost: "$29900 - $52000",
    costAfterTaxCredit: "$20930 - $36400",
  },
  {
    systemSizeInkW: "13.5",
    avgCost: "$31050 - $54000",
    costAfterTaxCredit: "$21735 - $37800",
  },
  {
    systemSizeInkW: "14",
    avgCost: "$32200 - $56000",
    costAfterTaxCredit: "$22540 - $39200",
  },
  {
    systemSizeInkW: "14.5",
    avgCost: "$33350 - $58000",
    costAfterTaxCredit: "$23345 - $40600",
  },
  {
    systemSizeInkW: "15",
    avgCost: "$34500 - $60000",
    costAfterTaxCredit: "$24150 - $42000",
  },
];
const roofMaterial = [
  { materialName: "Shingle", avgCost: "$4,500 - $21,000" },
  { materialName: "Wood Shake Shingle", avgCost: "$13,500 - $25,000" },
  { materialName: "Metal", avgCost: "$20,000 - $38,000" },
  { materialName: "Tile", avgCost: "$12,000 - $30,000" },
  { materialName: "Flat roof", avgCost: "$7,500 - $25,000" },
];

const initialValues = {
  service: process.env.REACT_APP_ROOFING_SERVICE_ID,
  // serviceIcon: "",
  zipCode: "",
  roofType: "",
  level: "",
  roofAge: "",
  propertyType: "",
  address: "",
  coordinates: null,
  addressZipCode: "",
  estimatedRoofArea: "",
  roofImage: "",
  numberOfRelevantProjects: 0,
  projects: [],
  projectsCosts: {
    min: 0,
    max: 0,
    mid: 0,
  },
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  partnerResponseId: "",
};

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const logosSettings = {
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
  cssEase: "linear",
  dots: false,
  infinite: true, // Set to true to loop back to the first logo
  slidesToShow: 4, // Show 4 logos at large screens
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true, // Ensure infinite scrolling on medium screens
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true, // Ensure infinite scrolling on small screens
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true, // Ensure infinite scrolling on very small screens
      },
    },
  ],
};

const options = {
  disableDefaultUI: true,
  // minZoom: 10,
  maxZoom: 14,
  mapId: "a216e88580f77d70",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
  draggable: false,
};

const formatReviewCount = (count) => {
  if (count >= 1000 && count < 10000) {
    return `${(count / 1000).toFixed(1)}k+`;
  } else if (count >= 10000) {
    return `${(count / 1000).toFixed(0)}k+`;
  }
  return count;
};

const SolarLandingPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const landingPageId = new URLSearchParams(location.search)?.get(
    "landing_page_id"
  );

  const [loading, setLoading] = useState(true);
  const [landingPageDetails, setLandingPageDetails] = useState(null);
  const [seeProjectsLoading, setSeeProjectsLoading] = useState(false);
  const [showProjectsResults, setShowProjectsResults] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [isMapLocationReady, setIsMapLocationReady] = useState(false);
  const [mapOptions, setMapOptions] = useState({
    disableDefaultUI: true,
    // minZoom: 10,
    maxZoom: 14,
    mapId: "a216e88580f77d70",
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      strictBounds: true,
    },
  });
  const [filterPreset, setFilterPreset] = useState(null);
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Select your roof type",
      status: "current",
      completed: true,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 2,
      title: "How many levels?",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 3,
      title: "How old is your roof?",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 4,
      title: "Select your property type",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 5,
      title: "Calculate roof area",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },

    {
      id: 6,
      title: "Estimated roof area",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 7,
      title: "Project Summary",
      status: "upcoming",
      completed: false,
      nextButtonText: "See Projects",
      prevButtonText: "Back",
    },
    {
      id: 8,
      title: "Enter your name (To verify you are a real person)",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 9,
      title: "What is your email address?",
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 10,
      title: "What is your phone number?",
      status: "upcoming",
      completed: false,
      nextButtonText: "Agree & See Projects",
      prevButtonText: "Back",
    },
    {
      id: 11,
      title: "No Service in Zip Code",
      message: "",
      status: "upcoming",
      completed: false,
      nextButtonText: "Yes, Contact Me",
      prevButtonText: "No, Thanks",
    },
  ]);
  const [formData, setFormData] = useState(initialValues);
  const [company, setCompany] = useState(null);
  const [services, setServices] = useState([]);
  const [appearanceSettings, setAppearanceSettings] = useState({
    headerBGColour: "#000",
  });
  const [brandsOffered, setBrandsOffered] = useState([]);

  const previousZoomRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Create and inject the TrustedForm script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      (document.location.protocol === "https:" ? "https" : "http") +
      "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" +
      new Date().getTime() +
      Math.random();

    // Append the script to the body
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures it runs only once

  useEffect(() => {
    try {
      if (id && landingPageId) {
        getCompanyById(id)
          .then((res) => {
            setCompany(res.data);
          })
          .catch((err) => {
            console.log(err);
          });

        getServicesByCompanyId(id)
          .then((res) => {
            setServices(res?.data);
          })
          .catch((err) => {
            console.log(err);
          });

        getBrandsOfferedByCompany(id)
          .then((res) => {
            setBrandsOffered(res?.data);
          })
          .catch((err) => {
            console.log(err);
          });

        getLandingPageById(landingPageId)
          .then((res) => {
            setLandingPageDetails(res.data);
            let coordinates;
            if (res?.data?.state !== "all") {
              coordinates = getStateCoordinates(res?.data?.state);
              setMapCenter(coordinates);
            } else {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    coordinates = { lat: latitude, lng: longitude };
                    setMapCenter(coordinates);
                  },
                  (error) => {
                    coordinates = { lat: 40.7128, lng: -74.006 };
                    setMapCenter(coordinates);
                  }
                );
              } else {
                coordinates = { lat: 40.7128, lng: -74.006 };
                setMapCenter(coordinates);
              }
            }
            // if (res?.data?.enableMapLocation) {
            //   setMapAccordingToUserLocationOrFilter(
            //     res.data.mapZoom,
            //     res.data.mapCenter
            //   );
            // } else {
            //   setMapAccordingToUserLocationOrFilter();
            // }

            if (res?.data?.appearanceSettings) {
              setAppearanceSettings(res.data.appearanceSettings);
            } else {
              // setMapAccordingToUserLocationOrFilter();
            }

            if (res?.data?.allowMapZoom) {
              setMapOptions({
                ...mapOptions,
                zoomControl: true,
              });
            } else {
              setMapOptions({
                ...mapOptions,
                zoomControl: false,
                scrollWheel: false,
              });
            }

            if (res?.data?.allowMapPan) {
              setMapOptions({
                ...mapOptions,
                draggable: true,
              });
            } else {
              setMapOptions({
                ...mapOptions,
                draggable: false,
              });
            }

            setFilterPreset(res.data);
          })
          .catch((err) => {
            console.log(err);
            setError("404: Landing page not found for the specified company.");
          });
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [id, landingPageId]);

  useEffect(() => {
    if (formData?.zipCode && isValidZipCode(formData?.zipCode)) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [formData?.zipCode, setIsNextDisabled]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      // console.log("Updated formData:", updatedFormData);
      return updatedFormData;
    });
  };

  const getRelatedProjects = async () => {
    const requestData = {
      serviceId: formData?.service,
      roofType: formData?.roofType,
      propertyType: formData?.propertyType,
      estimatedRoofArea: formData?.estimatedRoofArea,
      companyId: company?._id,
    };

    if (landingPageDetails?.state !== "all") {
      requestData.state = landingPageDetails?.state;
    }

    await getRelevantProjects(requestData)
      .then(({ data }) => {
        setFormData({
          ...formData,
          numberOfRelevantProjects: data?.projects?.length || 0,
          projects: data?.projects || [],
          projectsCosts: {
            min: data?.minCost,
            max: data?.maxCost,
            mid: data?.midCost,
          },
          // serviceIcon: data?.serviceIcon,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showProjectResults = async () => {
    const result = await storePartnersPageResopnseInDatabase(company?._id, {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      customerEmail: formData?.email,
      phoneNo: formData?.phoneNo,
      address: formData?.address,
      partnerId: company?._id,
      landingPageId: landingPageId,
      serviceId: landingPageDetails?.service,
      queryDetails: {
        roofType: formData?.roofType,
        level: formData?.level,
        roofAge: formData?.roofAge,
        propertyType: formData?.propertyType,
        estimatedRoofArea: formData?.estimatedRoofArea,
      },
    });

    const isZipInList = landingPageDetails?.zipCodesListId?.zipCodes?.includes(
      formData?.addressZipCode
    );
    const isRoofTypeInList = landingPageDetails?.roofMaterialsList?.includes(
      formData?.roofType
    );

    if (isZipInList && isRoofTypeInList) {
      // Both checks passed
      setShowProjectsResults(true);
      setOpen(false); // Close the popup
      return true; // Signal that popup was closed
    } else {
      // Handle cases where zip code or roof type is not in the list
      let stepTitle = "";
      let stepMessage = "";

      if (!isZipInList) {
        stepTitle = "Sorry!";
        stepMessage = `<p>Unfortunately <span className=\"font-bold\">${
          company?.name
        }</span> does not service your area. Would you like Pro-Mapper to contact you at 
        ${
          formData.phoneNo
            ? formatPhoneNumber(formData?.phoneNo)
            : formData?.email
        }
        and help connect you with a qualified partner in your area?
      </p>`;
      } else if (!isRoofTypeInList) {
        stepTitle = "Sorry!";
        stepMessage = `<p>Unfortunately <span className=\"font-bold\">${
          company?.name
        }</span> does not work with your roof material. Would you like Pro-Mapper to contact you at 
      ${
        formData.phoneNo
          ? formatPhoneNumber(formData?.phoneNo)
          : formData?.email
      }
      and help connect you with a qualified partner in your area?
    </p>`;
      }

      // Set the steps to change the title of the last step
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[10].title = stepTitle;
        updatedSteps[10].message = stepMessage;
        return updatedSteps;
      });

      // Update form data with the partner response ID for further use
      setFormData({ ...formData, partnerResponseId: result?.data?._id });

      return false; // Signal that popup remains open
    }
  };

  const handleNextStep = async () => {
    if (activeStep === 6) {
      getRelatedProjects();
    } else if (activeStep === 10) {
      await showProjectResults(); // Let showProjectResults handle popup logic
      if (!open) return; // Stop further execution if popup closes
    } else if (activeStep === 11) {
      await updateContactAndZipStatus(formData?.partnerResponseId, {
        isZipCodeInList: false,
        shouldAdminContact: true,
      });
      setFormData(initialValues);
      setOpen(false); // Close the popup at step 10
      setActiveStep(1); // Reset steps if needed
      return; // Prevent further step updates
    }

    // Update step state only if popup is not closed
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[activeStep - 1].status = "completed"; // Mark current as completed
      updatedSteps[activeStep].status = "current"; // Mark next as current
      return updatedSteps;
    });
    setActiveStep((prevStep) => prevStep + 1); // Move to the next step
  };

  const handlePrevStep = async () => {
    if (activeStep === 10) {
      await updateContactAndZipStatus(formData?.partnerResponseId, {
        isZipCodeInList: false,
        shouldAdminContact: false,
      });
      setFormData(initialValues);
      setOpen(false);
      setActiveStep(1);
    }
    if (activeStep > 1) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[activeStep - 1].status = "upcoming"; // Mark current as upcoming
        updatedSteps[activeStep - 2].status = "current"; // Mark previous as current
        return updatedSteps;
      });
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleFinish = () => {
    setOpen(false); // Close the modal when Finish is clicked on the last step
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
        <h1 className="text-4xl font-bold text-red-600 mb-4">{error}</h1>
        <p className="text-lg text-gray-700 mb-8">
          We couldn't find the landing page you were looking for. Please check
          the URL.
        </p>
      </div>
    );
  }

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        initialValues={initialValues}
        formData={formData}
        appearanceSettings={appearanceSettings}
        setFormData={setFormData}
        handleChange={handleChange}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        handleFinish={handleFinish}
        isNextDisabled={isNextDisabled}
        showProjectResults={showProjectResults}
      >
        <ActiveStep
          steps={steps}
          activeStep={activeStep}
          formData={formData}
          company={company}
          appearanceSettings={appearanceSettings}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      </Modal>
      <div className="">
        <header className="relative z-50 mx-auto max-w-7xl">
          {" "}
          {/* Changed absolute to relative */}
          <nav
            aria-label="Global"
            className="flex items-center justify-between p-2 lg:px-8"
          >
            <div className="flex lg:flex-1 items-center gap-2">
              <img
                alt=""
                src={
                  company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""
                }
                className="h-12 md:h-20 w-auto"
              />
              <span className="text-sm md:text-lg lg:text-2xl font-bold text-gray-700">
                {company?.name}
              </span>
            </div>
            <div className="lg:flex lg:flex-1 lg:justify-end">
              <div className="bg-slate-300 bg-opacity-60 px-1 py-1 rounded-md flex-col md:flex">
                <p className="pt-1 px-2 lg:px-3 text-gray-900 text-xs font-medium">
                  Powered by
                </p>
                <img src={logo} alt="Logo" className="h-6" />
              </div>
            </div>
          </nav>
        </header>

        <div
          className="relative isolate overflow-hidden"
          style={{ background: appearanceSettings?.headerBGColour }}
        >
          {showProjectsResults ? (
            <RoofingResults
              formData={formData}
              logosSettings={logosSettings}
              brandsOffered={brandsOffered}
              company={company}
              landingPageDetails={landingPageDetails}
              appearanceSettings={appearanceSettings}
            />
          ) : (
            <>
              <svg
                aria-hidden="true"
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              >
                <defs>
                  <pattern
                    x="50%"
                    y={-1}
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={100}
                    height={100}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-600">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                  />
                </svg>
                <rect
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                />
              </svg>
              <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col gap-y-8 items-start lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-10 lg:px-8 lg:py-24">
                <div className="relative w-full mt-8 sm:mt-12 lg:mt-0 flex-shrink-0 flex-grow">
                  <GoogleMap
                    options={options}
                    mapContainerStyle={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "1rem",
                    }}
                    center={mapCenter}
                    zoom={8}
                  ></GoogleMap>
                  {/* Add SVG pins */}
                  <svg
                    className="absolute"
                    style={{
                      top: "260px",
                      left: "50px",
                      fill: appearanceSettings?.primaryColour,
                    }}
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  <svg
                    className="absolute"
                    style={{
                      top: "200px",
                      left: "150px",
                      fill: appearanceSettings?.primaryColour,
                    }}
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  <svg
                    className="absolute"
                    style={{
                      top: "330px",
                      left: "200px",
                      fill: appearanceSettings?.primaryColour,
                    }}
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  {company?.launchYear && (
                    <div className="absolute bg-gray-100 h-20 rounded-md -top-5 -left-4 shadow-lg py-4 px-2 grid grid-cols-3 gap-2">
                      <div className="flex justify-center items-center rounded-full bg-red-200 w-12 h-12 col-span-1">
                        <PiMedalFill className="text-orange-600 w-6 h-6" />
                      </div>
                      <div className="col-span-2 flex flex-col">
                        <h3 className="font-extrabold text-xl">{`${
                          new Date().getFullYear() - company?.launchYear
                        }+`}</h3>
                        <p className="font-normal text-gray-500 text-sm">
                          Years Experience
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="absolute bg-gray-100 rounded-md bottom-3 right-3 shadow-lg h-14 px-1 grid grid-cols-3 gap-2">
                    <div className="flex flex-col justify-center items-center col-span-1">
                      <div className="w-10 h-10 rounded-md bg-sky-500 flex justify-center items-center">
                        <FaRankingStar className="text-white w-6 h-6" />
                      </div>
                    </div>
                    <div className="col-span-2 flex flex-col justify-center">
                      <h3 className="font-bold text-xl">98%</h3>
                      <p className="font-normal text-gray-500 text-sm">
                        Satisfaction Rate
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-16 right-5">
                    <div
                      className="rounded-2xl p-1"
                      style={{
                        background: `linear-gradient(to right, ${appearanceSettings?.primaryColour}, transparent)`,
                      }}
                    >
                      <div className="bg-white rounded-[calc(1.5rem-1px)]">
                        <img
                          className="w-80 rounded-2xl object-cover border-4 border-white"
                          src={heroImg}
                          alt="Map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:flex lg:items-center lg:h-full w-full flex">
                  <div className="flex flex-col gap-y-6">
                    <h1 className="text-4xl font-bold text-white sm:text-6xl">
                      AI Powered Solar Calculator
                    </h1>
                    <p className="text-lg font-normal text-white sm:text-xl">
                      See projects in your local area
                    </p>
                    <form
                      className="md:flex md:items-center md:gap-x-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        setOpen(true);
                      }}
                    >
                      <div>
                        <input
                          id="zipCode"
                          name="zipCode"
                          type="text"
                          value={formData?.zipCode}
                          onChange={handleChange}
                          placeholder="Enter your zip"
                          className="block w-72 rounded-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm"
                        />
                      </div>
                      <button
                        onClick={() => setOpen(true)}
                        type="button"
                        disabled={isNextDisabled}
                        className={`md:mt-0 mt-4 flex items-center gap-x-2 rounded-full px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 text-nowrap`}
                        style={{
                          backgroundColor: isNextDisabled
                            ? "#ccc"
                            : appearanceSettings?.primaryColour || "#00458f",
                          cursor: isNextDisabled ? "not-allowed" : "pointer",
                        }}
                      >
                        Find Projects
                        <FaCircleArrowRight className="w-5" />
                      </button>
                    </form>
                    {company?.reviewScore && company?.reviewCount && (
                      <div className="md:flex md:gap-6">
                        <div className="flex -space-x-4 rtl:space-x-reverse">
                          <img
                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                            src={People1}
                            alt=""
                          />
                          <img
                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                            src={People2}
                            alt=""
                          />
                          <img
                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                            src={People3}
                            alt=""
                          />
                          <a
                            className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800"
                            href="#"
                          >
                            +{formatReviewCount(company?.reviewCount) - 3}
                          </a>
                        </div>
                        <div className="flex flex-col gap-y-2 md:mt-0 mt-4">
                          <div className="flex">
                            {Array.from({
                              length: Math.floor(company?.reviewScore),
                            }).map((_, index) => (
                              <svg
                                key={index}
                                className="w-4 h-4 text-yellow-300 me-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            ))}
                            {Array.from({
                              length: 5 - Math.floor(company?.reviewScore),
                            }).map((_, index) => (
                              <svg
                                key={index}
                                className="w-4 h-4 text-gray-400 me-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            ))}
                          </div>
                          <div className="flex text-white">
                            <p className="ms-1 text-sm font-medium">{`${company?.reviewScore}/5`}</p>
                            <p className="ms-1 text-sm font-medium">out of</p>
                            <p className="ms-1 text-sm font-medium">{`${formatReviewCount(
                              company?.reviewCount
                            )} reviews`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full h-full relative overflow-hidden">
          <div
            className="w-full h-full bg-no-repeat bg-cover z-0 py-16"
            style={{ backgroundImage: `url(${RoofingSectionBackground})` }}
          >
            <div className="mx-auto max-w-7xl h-full flex items-center">
              <div className="flex flex-col max-w-xl px-6 lg:px-0">
                <h2
                  className="text-3xl font-bold mb-4"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Solar System Installation Cost Data{" "}
                  {landingPageDetails?.state === "all"
                    ? ""
                    : getFullStateName(landingPageDetails?.state)}
                </h2>
                <h2
                  className="text-2xl font-bold mb-4"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Last updated:{" "}
                  <span className="font-medium text-sky-500">
                    {landingPageDetails?.lastProjectCreated.split("T")[0]}
                  </span>
                </h2>
                <h2
                  className="text-lg font-bold"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  System Sizes:{" "}
                  <span className="font-medium text-sky-500">
                    ${formatNumber(landingPageDetails?.minNormalCost)} - $
                    {formatNumber(landingPageDetails?.maxNormalCost)}
                  </span>
                </h2>
                <h2
                  className="text-lg font-bold"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Normal Range:{" "}
                  <span className="font-medium text-sky-500">
                    ${formatNumber(landingPageDetails?.minNormalCost)} - $
                    {formatNumber(landingPageDetails?.maxNormalCost)}
                  </span>
                </h2>
                <p className="text-md font-normal mt-4">
                  Solar System costs{" "}
                  {landingPageDetails?.state === "all"
                    ? ""
                    : `in ${getFullStateName(landingPageDetails?.state)}`}{" "}
                  range from{" "}
                  <span className="text-sky-500 font-semibold">
                    ${formatNumber(landingPageDetails?.minCost)} - $
                    {formatNumber(landingPageDetails?.maxCost)}
                  </span>{" "}
                  for cash purchases and{" "}
                  <span className="text-sky-500 font-semibold">
                    ${formatNumber(landingPageDetails?.minCost)} - $
                    {formatNumber(landingPageDetails?.maxCost)}
                  </span>{" "}
                  per month for financed systems. With most homeowners paying{" "}
                  <span className="text-sky-500 font-semibold">
                    ${formatNumber(landingPageDetails?.medianCost)}
                  </span>
                  .
                </p>
                <p className="text-md font-normal">
                  A solar installation requires extensive electrical and roof
                  workmanship. Having a quality solar system, installed by a
                  quality contractor ensures your system will remain in good
                  working order and provide substantial returns for many years
                  to come.
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => window.scrollTo(0, 0)}
                    className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm"
                    style={{ background: appearanceSettings?.primaryColour }}
                  >
                    Calculate what size system you need & see similar projects
                    <FaCircleArrowRight className="w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-6 mx-4">
          <div className="flex flex-col justify-center gap-3">
            {/* <h4
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              How roof size affects cost
            </h4>
            <p>
              The average roof size{" "}
              {landingPageDetails?.state === "all"
                ? ""
                : `in ${getFullStateName(landingPageDetails?.state)}`}{" "}
              is between {formatNumber(landingPageDetails?.minAverageRoofSize)}{" "}
              - {formatNumber(landingPageDetails?.maxAverageRoofSize)} sq Feet.
              The size of your roof plays a significant role in determining the
              cost to replace it. Larger roofs require more materials and labor,
              which naturally increases the overall expense. A bigger roof means
              not only additional shingles, tiles, or metal panels but also
              extended underlayment, flashing, and fasteners to ensure a secure
              installation. Labor costs also rise as roof size increases since
              the job will take longer to complete. In addition, larger roofs
              may require more safety equipment and cleanup efforts, adding to
              the total cost. Therefore, when budgeting for a roof replacement,
              understanding your roof's size can help set realistic expectations
              for the project’s expense.
            </p> */}

            <div className="mt-2 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
                    <thead className="bg-gray-900 rounded-t-lg">
                      <tr className="text-white">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                        >
                          System Size (kW)
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                        >
                          Average Cost
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Cost After Tax Credit
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {roofData.map((data, index) => (
                        <tr
                          key={data.systemSizeInkW}
                          className={`even:bg-gray-50 ${
                            index === roofData.length - 1 ? "rounded-b-lg" : ""
                          }`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r border-gray-300">
                            {data.systemSizeInkW}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:pl-3 border-r border-gray-300">
                            {data.avgCost}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {data.costAfterTaxCredit}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <div>
              <button
                type="button"
                onClick={() => window.scrollTo(0, 0)}
                className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                style={{ background: appearanceSettings?.primaryColour }}
              >
                Calculate your roof area & see similar projects
                <FaCircleArrowRight className="w-5" />
              </button>
            </div> */}
          </div>
          <div className="flex flex-col justify-center gap-3 mt-6">
            <h4
              className="text-3xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Important factors determine what size solar system you need.
            </h4>
            {/* <p>
              While asphalt shingles are the most popular choice for homeowners
              due to their affordability, durability, and ease of installation,
              there are several other roofing options available. Each of these
              alternatives comes with its own unique advantages and
              considerations, allowing you to choose the best fit for your
              specific needs, style preferences, and budget.
            </p>

            <div className="mt-2 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
                    <thead className="bg-gray-900 rounded-t-lg">
                      <tr className="text-white">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                        >
                          Roof Material
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Average Cost (for 2,500 Sq ft roof)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {roofMaterial.map((data, index) => (
                        <tr
                          key={data.materialName}
                          className={`even:bg-gray-50 ${
                            index === roofData.length - 1 ? "rounded-b-lg" : ""
                          }`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r border-gray-300">
                            {data.materialName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {data.avgCost}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={() => window.scrollTo(0, 0)}
                className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                style={{ background: appearanceSettings?.primaryColour }}
              >
                Calculate your roof area & see similar projects
                <FaCircleArrowRight className="w-5" />
              </button>
            </div> */}
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-6 mx-4">
          <div className="relative">
            <div className="md:float-right md:w-[45%] md:ml-4 mb-4 md:mb-0">
              <img
                src={sectionImg1}
                alt="Example"
                className="w-full rounded-lg object-cover"
              />
            </div>

            {/* Text content with a responsive gap */}
            <div className="space-y-3">
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Location
              </h3>
              <p>
                The size of the solar system you need depends heavily on your
                location. Factors like average sunlight hours, climate, and
                regional energy costs influence how much energy your system must
                produce. For instance, areas with abundant sunlight typically
                require fewer panels to generate the same amount of electricity
                compared to cloudier regions or shaded roofs. Additionally,
                local weather patterns and seasonal variations may affect how
                efficiently your system performs, making it crucial to tailor
                the solar array to your specific geographical conditions.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Roof Orientation
              </h3>
              <p>
                South-facing roofs in the northern hemisphere typically receive
                the most sunlight throughout the day, allowing for optimal
                energy production with fewer panels. East- or west-facing roofs
                may require a larger system to produce the same amount of energy
                since they receive less direct sunlight. Similarly, if your roof
                is shaded by trees or nearby structures, you may need additional
                panels or a more powerful system to meet your energy needs
                effectively. Adjusting the system size based on roof orientation
                ensures maximum efficiency and cost savings.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Roof Pitch
              </h3>
              <p>
                A steep roof may limit the space available for panels,
                potentially requiring a more efficient system to meet energy
                needs. Conversely, a flat or low-pitched roof may require
                additional mounting equipment to angle the panels correctly,
                ensuring optimal energy production. By accounting for roof
                pitch, you can design a solar system that efficiently harnesses
                sunlight without oversizing.
              </p>
            </div>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="relative">
            {/* Text content with a responsive gap */}
            <div className="space-y-3">
              <h3
                className="md:text-3xl text-2xl font-bold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Factors that determine the price of a solar system.
              </h3>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Local & Federal Incentives
              </h3>
              <p>
                The Investment Tax Credit (ITC) and potential local incentives
                can significantly reduce the cost of your solar system. The ITC
                allows you to deduct a percentage of your system’s installation
                cost from your federal taxes, making solar more affordable
                upfront. Additionally, many states and local governments offer
                rebates, tax breaks, or performance-based incentives that can
                further lower your total investment. By combining the ITC with
                these local benefits, you can maximize your savings and achieve
                a faster return on investment.
                <br />
                You should consult a licences tax professional prior to
                purchasing a solar system to confirm your eligibility and using
                a knowledgeable local contractor can help you ensure you are
                aware of all the incentives available and how to claim them.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Inverter Type
              </h3>
              <p>
                The type of inverter you choose can influence both the price and
                performance of your solar system. String inverters are typically
                more cost-effective and suitable for simple installations, while
                microinverters, which are installed on each panel, provide
                greater flexibility and efficiency in systems with shading or
                varying panel orientations. Hybrid inverters, designed for
                battery storage integration, can add to the upfront cost but
                enhance long-term energy savings and resilience. Selecting the
                right inverter type ensures you get the best balance of
                affordability and performance tailored to your specific energy
                needs.
                <br /> A qualified solar contractor can help you decide which
                option suits your needs best.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Roof Area
              </h3>
              <p>
                The available roof area directly impacts the price of a solar
                system, as it determines how many panels can be installed and
                the system size needed to meet your energy goals. A larger roof
                area provides more flexibility to install enough panels
                efficiently, potentially lowering costs per watt. However,
                smaller or irregularly shaped roofs may require more advanced
                panel technology or customized designs, which can increase the
                price. Maximizing your roof's usable space ensures an optimal
                balance between cost and energy production.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Roof Material & Mounting System
              </h3>
              <p>
                The type of roof material and the mounting system required can
                significantly affect the price of a solar system. Standard
                materials like asphalt shingles are often more affordable for
                solar installations due to their compatibility with common
                mounting systems. In contrast, roofs made of tile, metal, or
                other specialized materials may require custom mounting
                solutions or additional labor, increasing installation costs.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Permitting Fees
              </h3>
              <p>
                Permitting and engineering fees are essential components of a
                solar system's total cost and can vary based on location and
                project complexity. Local governments typically require permits
                to ensure safety and compliance, and the fees for these can
                differ significantly by region. Additionally, customized
                engineering plans may be needed for unique roof designs or
                ground-mounted systems, which can add to upfront expenses. While
                these fees contribute to the overall price, they are crucial for
                a smooth installation process and long-term system reliability.
              </p>
              <div>
                <div className="my-4 grid gap-3 md:gap-6 grid-cols-8">
                  {/* Left-side image covering 60% width on medium and larger screens */}
                  <div className="col-span-5 h-72 md:h-[560px]">
                    <img
                      src={sectionImg2}
                      alt="Left"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>

                  {/* Right-side images covering 40% width on medium and larger screens */}
                  <div className="col-span-3 grid grid-rows-2 gap-3 md:gap-6 h-72 md:h-[560px]">
                    <img
                      src={sectionImg3}
                      alt="Top Right"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <img
                      src={sectionImg4}
                      alt="Bottom Right"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="space-y-3">
            <h3
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Other important factors to consider:
            </h3>

            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Installation quality
            </h3>
            <p>
              Installation quality is a critical factor when purchasing a solar
              system for your home, as it directly impacts the system’s
              performance, durability, and safety. Poorly installed panels or
              electrical components can lead to inefficiencies, potential
              damage, or even safety hazards over time. Choosing experienced and
              certified installers ensures that your system is correctly
              positioned, securely mounted, and properly wired for optimal
              energy production. High-quality installation also protects your
              investment by reducing maintenance needs and extending the
              lifespan of your solar system.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Balance of System
            </h3>
            <p>
              Contractors will often only talk about the major components of a
              solar system such as the panels and inverter/s. Components like
              cables, wiring, isolators, and conduit are essential to a safe and
              efficient solar system installation. High-quality cables and
              wiring ensure reliable energy transmission from the panels to the
              inverter and your home, while isolators allow for safe system
              shut-off during maintenance or emergencies. Properly installed
              conduit protects the wiring from weather and physical damage,
              ensuring long-term durability. Investing in top-tier components
              not only enhances system performance but also increases safety and
              reduces the risk of future repairs.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Warranties
            </h3>
            <p>
              When investing in a solar system, it's important to understand the
              warranties offered for the panels, inverters, and installation.
              Solar panels typically come with warranties ranging from 20 to 25
              years, ensuring they continue to perform at high efficiency over
              time. Inverters, which convert the energy produced by the panels
              into usable electricity, generally have shorter warranties,
              usually between 5 to 15 years, as they are more prone to wear and
              tear.
              <br />
              <br /> In addition to the equipment warranties, many installers
              offer workmanship warranties that cover the quality of the
              installation for 5 to 10 years. These warranties provide peace of
              mind, ensuring that any issues arising from improper installation
              are addressed without additional cost to you. Choosing a reputable
              installer with strong warranty offerings helps protect your
              investment and ensures your solar system will deliver reliable,
              long-term performance.
            </p>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4 py-2 md:py-5 lg:py-10">
          <div className="flex justify-between">
            <h2
              className="md:text-3xl text-2xl font-bold mb-3"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              The Services We Provide
            </h2>
          </div>

          <div className="overflow-hidden mt-5 slider-container">
            <Slider {...settings}>
              {services?.map((service, index) => (
                <div key={index} className="flex justify-center">
                  <div className="mx-2 lg:col-span-4 md:col-span-6 col-span-12 h-full flex">
                    <div
                      className="rounded-lg bg-gradient-to-t from-sky-600 via-transparent to-transparent p-[3px] h-full w-full"
                      style={{
                        boxShadow: "0px 4px 34px 0px #0000000A",
                        background: `linear-gradient(to top, ${appearanceSettings?.secondaryColour}, transparent 50%)`,
                      }}
                    >
                      <div
                        className="w-full rounded-lg relative bg-white flex flex-col h-full min-h-[400px]"
                        style={{
                          boxShadow: "0px 4px 34px 0px #0000000A",
                        }}
                      >
                        {/* Image at the top with fixed height */}
                        <div className="w-full h-48 flex-shrink-0">
                          {" "}
                          {/* Ensures image height is consistent */}
                          <img
                            className="w-full h-full object-cover rounded-t-lg"
                            src={RoofArea}
                            alt={service?.serviceId?.name}
                          />
                        </div>

                        {/* Service name below the image */}
                        <div className="px-4 py-2">
                          <h5 className="text-lg font-bold text-gray-900">
                            {service?.serviceId?.name}
                          </h5>
                        </div>

                        {/* List of sub-services */}
                        <div className="px-4 py-2 flex flex-col items-start space-y-2 flex-grow">
                          <ul className="list-disc ml-5">
                            {service?.workTypes?.map((workType) => (
                              <li
                                key={workType?._id}
                                className="text-base text-gray-700"
                              >
                                {workType?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-3 md:py-5 lg:py-10" id="logos">
          <h2 className="text-3xl text-center font-semibold mb-3">
            Trusted Brands
          </h2>

          {/* Slider Component for logos */}
          <div className="overflow-hidden mt-5 slider-container">
            <Slider {...logosSettings}>
              {brandsOffered?.map((brand, index) => (
                <div key={index} className="flex justify-center items-center">
                  {" "}
                  {/* Ensures vertical centering */}
                  <div className="mx-4 flex items-center justify-center">
                    <div className="p-4 rounded-lg transform transition-transform hover:scale-105 flex items-center justify-center">
                      {/* Image Section */}
                      <div className="w-auto max-h-28 flex items-center justify-center">
                        {brand?.logo ? (
                          <img
                            className="object-contain max-h-28 max-w-full"
                            src={`${baseURL}s3/images/${brand?.logo}`}
                            alt={brand?.name}
                          />
                        ) : (
                          <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center">
                            <span className="text-xl font-bold text-gray-500">
                              {brand?.name
                                ? brand.name
                                    .split(" ")
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()
                                : "N/A"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <footer style={{ backgroundColor: appearanceSettings?.headerBGColour }}>
          <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col lg:flex-row justify-between items-center lg:px-8 gap-x-4">
            {/* Logo Section */}
            <div className="order-2 lg:order-2 flex flex-col items-center lg:items-start space-y-4">
              <div className="bg-white px-2 py-1 rounded-md flex items-center">
                <p className="px-2 lg:px-3 text-gray-900 text-sm font-medium">
                  Powered by
                </p>
                <img src={logo} alt="Logo" className="h-6" />
              </div>
              <div className="flex space-x-4">
                <Link
                  to="/terms-and-conditions"
                  className="text-sm font-light hover:underline"
                  style={{ color: appearanceSettings?.paragraphTextColour }}
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/privacy-policy"
                  className="text-sm font-light hover:underline"
                  style={{ color: appearanceSettings?.paragraphTextColour }}
                >
                  Privacy Policy
                </Link>
              </div>
            </div>

            {/* Company Logo */}
            <a href="#" className="order-1 lg:order-1">
              <span className="sr-only">{company?.name}</span>
              <img
                alt={company?.name || "Company Logo"}
                src={
                  company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""
                }
                className="h-16 w-auto filter brightness-0 invert"
              />
            </a>
          </div>

          <hr className="border-gray-500" />

          {/* Footer Bottom */}
          <div className="py-4 text-center">
            <span
              className="font-light text-sm"
              style={{ color: appearanceSettings?.paragraphTextColour }}
            >
              © 2024 Pro-Mapper. All rights reserved.
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default SolarLandingPage;
