import React from "react";
import RoofType from "./RoofType";
import Levels from "./Levels";
import RoofAge from "./RoofAge";
import CalculateRoofArea from "./CalculateRoofArea";
import VerifyHuman from "./VerifyHuman";
import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import RoofArea from "../../../assets/images/RoofArea.jpg";
import ShowRoofArea from "./ShowRoofArea";
import { getRelevantProjects } from "../../../redux/api";
import { Oval } from "react-loader-spinner";
import ProjectsSummary from "./ProjectsSummary";
import NoServiceInZip from "./NoServiceInZip";
import PropertyType from "./PropertyType";

const ActiveStep = ({
  steps,
  activeStep,
  formData,
  setFormData,
  appearanceSettings,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
  company,
}) => {
  return (
    <>
      {activeStep === 1 ? (
        <RoofType
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 2 ? (
        <Levels
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 3 ? (
        <RoofAge
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 4 ? (
        <PropertyType
          formData={formData}
          setFormData={setFormData}
          appearanceSettings={appearanceSettings}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 5 ? (
        <CalculateRoofArea
          formData={formData}
          setFormData={setFormData}
          appearanceSettings={appearanceSettings}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 6 ? (
        <ShowRoofArea
          formData={formData}
          setFormData={setFormData}
          appearanceSettings={appearanceSettings}
          handleChange={handleChange}
        />
      ) : activeStep === 7 ? (
        <ProjectsSummary
          formData={formData}
          setFormData={setFormData}
          appearanceSettings={appearanceSettings}
          handleChange={handleChange}
        />
      ) : activeStep === 8 ? (
        <VerifyHuman
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 9 ? (
        <Email
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      ) : activeStep === 10 ? (
        <PhoneNumber
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleSubmit={handleNextStep}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
          company={company}
        />
      ) : activeStep === 11 ? (
        <NoServiceInZip company={company} formData={formData} steps={steps} />
      ) : (
        <>
          <div className="flex justify-center items-center h-full">
            <Oval
              height={28}
              width={28}
              color={appearanceSettings?.primaryColour}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={appearanceSettings?.secondaryColour}
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ActiveStep;
