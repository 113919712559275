import { GoogleMap, MarkerClustererF, MarkerF } from "@react-google-maps/api";
import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  baseURL,
  getFilterPresetById,
  getOfficesByBounds,
  getProjectsCreatedByUser,
  getUserById,
  createPartnersPageResponse,
} from "../../redux/api";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { Autocomplete } from "@react-google-maps/api";
import ProjectDetailsPopup from "../UserProjectsMap/ProjectDetailsPopup";
import { FaSearchLocation, FaUserCircle } from "react-icons/fa";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";
import OfficeDetailsPopup from "../UserProjectsMap/OfficeDetailsPopup";
import { create } from "@mui/material/styles/createTransitions";
import { success } from "../../utils/notification";
import { RotatingLines } from "react-loader-spinner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const initialFilter = {
  service: null,
  workType: null,
  minCost: "",
  maxCost: "",
  date: {
    startDate: null,
    endDate: null,
  },
  companies: null,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  // minZoom: 10,
  maxZoom: 14,
  scaleControl: true,
  mapId: "a216e88580f77d70",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
};

const initialData = {
  name: "",
  email: "",
};

const PartnersLandingPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const filterId = new URLSearchParams(location.search)?.get("filter");
  const [showPage, setShowPage] = useState(false);
  const [data, setData] = useState(initialData);
  const [isMapLocationReady, setIsMapLocationReady] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [filter, setFilter] = useState(initialFilter);
  const [filterPreset, setFilterPreset] = useState(null);
  const [projectDataFilters, setProjectDataFilters] = useState([]);
  const [projectDetailsPopupOpen, setProjectDetailsPopupOpen] = useState(false);
  const [filterCardPopupOpen, setFilterCardPopupOpen] = useState(false);
  const [contactPopupOpen, setContactPopupOpen] = useState(false);
  const [boundsChanged, setBoundsChanged] = useState(false);
  const [selectedClusterProjects, setSelectedClusterProjects] = useState([]);
  const [projectsPopupPosition, setProjectsPopupPosition] = useState(null);
  const [clusterProjectsPopupOpen, setClusterProjectsPopupOpen] =
    useState(false);
  const [projects, setProjects] = useState([]);
  const [searchFromDb, setSearchFromDb] = useState(true);
  const [map, setMap] = useState(null);
  const [visibleCoords, setVisibleCoords] = useState(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [projectDetailsId, setProjectDetailsId] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 40.727207,
    lng: -74.018186,
  });
  const [iframePage, setIframePage] = useState(false);
  const [offices, setOffices] = useState([]);
  const [officeMarkerIcon, setOfficeMarkerIcon] = useState(null);
  const [mapClustererColour, setMapClustererColour] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [textColour, setTextColour] = useState("");
  const [officeDetailsPopupOpen, setOfficeDetailsPopupOpen] = useState(false);
  const [officeId, setOfficeId] = useState(null);
  const [companyInfoSidebarOpen, setCompanyInfoSidebarOpen] = useState(
    window.innerWidth >= 768
  );
  const [loader, setLoader] = useState(false);
  const [isInIframe, setIsInIframe] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setCompanyInfoSidebarOpen(true);
      } else {
        setCompanyInfoSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const inIframe = window.self !== window.top;
    setIsInIframe(inIframe);
  }, []);

  useEffect(() => {
    if (map) {
      if (filterId) {
        getFilterPresetById(filterId)
          .then((res) => {
            if (res.data.enableMapLocation) {
              setMapAccordingToUserLocationOrFilter(
                res.data.mapZoom,
                res.data.mapCenter
              );
            } else {
              setMapAccordingToUserLocationOrFilter();
            }

            setFilterPreset(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setMapAccordingToUserLocationOrFilter();
      }
    }
  }, [filterId, map]);

  useEffect(() => {
    if (id) {
      getUserById(id)
        .then((res) => {
          setUser(res.data);
          if (res?.data?.company?.advertise) {
            setShowPage(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const setMapAccordingToUserLocationOrFilter = (zoom, center) => {
    if (zoom && center) {
      setMapCenter(center);
      map.setZoom(zoom);
      options.zoom = zoom;
      setIsMapLocationReady(true);
      onBoundsChanged();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setIsMapLocationReady(true);
          onBoundsChanged();
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  };

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const onBoundsChanged = () => {
    const bounds = map?.getBounds();
    if (!bounds) return;
    const ne = bounds?.getNorthEast();
    const sw = bounds?.getSouthWest();

    const coordinates = {
      ne: {
        lat: ne.lat(),
        lng: ne.lng(),
      },
      sw: {
        lat: sw.lat(),
        lng: sw.lng(),
      },
    };
    setVisibleCoords([
      [coordinates.sw.lng, coordinates.sw.lat],
      [coordinates.ne.lng, coordinates.ne.lat],
    ]);

    if (searchFromDb && visibleCoords) {
      getProjectsFromDatabase();
    }
  };

  useEffect(() => {
    // Check if filterId exists, and run the function accordingly
    if (isMapLocationReady && user && map) {
      if (filterId && filterPreset) {
        // If filterId exists and filterPreset is ready, fetch projects with the filterPreset
        getProjectsFromDatabase(filterPreset);
      } else if (!filterId) {
        // If no filterId exists, fetch projects without a filter preset
        getProjectsFromDatabase();
      }
    }
  }, [filterPreset, filterId, isMapLocationReady, user, map]);

  const getProjectsFromDatabase = (preset) => {
    setProjects([]);
    setOffices([]);
    setProjectsLoading(true);

    const searchParams = preset?.filter || filterPreset?.filter; // Use preset if available, otherwise use the existing filter
    // console.log("searchParams", searchParams);
    if (user && isMapLocationReady) {
      console.trace("getProjectsFromDatabase");

      const filterParams = {
        filterPresetId: filterId,
        service: searchParams?.service,
        workType: searchParams?.workType,
        minDate: searchParams?.date?.startDate,
        maxDate: searchParams?.date?.endDate,
        minCost:
          searchParams?.minCost === "" ? null : Number(searchParams?.minCost),
        maxCost:
          searchParams?.maxCost === "" ? null : Number(searchParams?.maxCost),
        projectDataFilters: searchParams?.projectDataFilters?.map((filter) => {
          if (filter?.type === "date") {
            if (filter?.subType === "range") {
              return {
                ...filter,
                minValue: new Date(filter?.value?.startDate)
                  ?.toISOString()
                  .split("T")[0],
                maxValue: new Date(filter?.value?.endDate)
                  ?.toISOString()
                  .split("T")[0],
              };
            } else {
              return {
                ...filter,
                value: new Date(filter?.value?.startDate)
                  ?.toISOString()
                  .split("T")[0],
              };
            }
          } else if (filter?.type === "number") {
            if (filter?.subType === "range") {
              return {
                ...filter,
                minValue:
                  filter?.minValue === null ? null : Number(filter?.minValue),
                maxValue:
                  filter?.maxValue === null ? null : Number(filter?.maxValue),
              };
            } else {
              return {
                ...filter,
                value: Number(filter?.value),
              };
            }
          } else {
            return filter;
          }
        }),
        mapBounds: visibleCoords,
      };

      getProjectsCreatedByUser(id, filterParams)
        .then((res) => {
          setProjects(res.data?.projects);
          setMapClustererColour(
            res?.data?.appearanceSettings?.mapClusterColour
          );
          setTextColour(res?.data?.appearanceSettings?.textColour);
          setBoundsChanged(false);
          setProjectsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
          setProjectsLoading(false);
        });

      // Get offices by bounds
      getOfficesByBounds(user?.company?._id, visibleCoords)
        .then((res) => {
          setOffices(res?.data?.offices);
          setOfficeMarkerIcon(res?.data?.mapAppearance?.officeMarkerIcon);
        })
        .catch((err) => {
          console.log("error is", err);
        });
    } else {
      setProjectsLoading(false);
    }
  };

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };
  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      const latlng = {
        lat: p?.geometry?.location?.lat(),
        lng: p?.geometry?.location?.lng(),
      };

      setMapCenter(latlng);
      setSearchFromDb(true);
    }
  };

  const handleClusterClick = (clusterer) => {
    const zoom = map.getZoom();
    if (zoom >= 14) {
      // Get center of cluster
      const center = clusterer.getCenter();
      const clusterProjects = projects?.filter((project) => {
        const [lng, lat] = project.coordinates;
        const position = { lat, lng };
        return (
          position.lat <= center.lat() + 0.0005 &&
          position.lat >= center.lat() - 0.0005 &&
          position.lng <= center.lng() + 0.0005 &&
          position.lng >= center.lng() - 0.0005
        );
      });
      setClusterProjectsPopupOpen(true);
      setSelectedClusterProjects(clusterProjects);

      // Calculate the offset for the popup position (move it slightly to the right)
      const offsetLng = center.lng() + 0.0002; // Adjust as needed

      // Set popup position to the right side and above the cluster
      setProjectsPopupPosition({
        lat: center.lat(),
        lng: offsetLng,
      });
    }
  };

  const closePopup = () => {
    setProjectsPopupPosition(null);
  };

  function generateClusterIcon(color, text) {
    return `
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.74 88.08">
          <defs>
            <style>
              .cls-1, .cls-2, .cls-3, .cls-4 { fill: ${color}; }
              .cls-1 { opacity: 0.8; }
              .cls-2 { opacity: 0.6; }
              .cls-3 { opacity: 0.4; }
            </style>
          </defs>
          <path class="cls-1" d="M42.36,82v3.07A36.95,36.95,0,0,1,15,60.44h0a36.81,36.81,0,0,1,0-22.74l2.66,1.53A33.84,33.84,0,0,0,42.36,82Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-1" d="M86.79,49.06a36.86,36.86,0,0,1-29.15,36V82A33.81,33.81,0,0,0,82.33,39.22L85,37.69A36.58,36.58,0,0,1,86.79,49.06Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-1" d="M77.33,24.46,74.67,26a33.73,33.73,0,0,0-49.34,0l-2.66-1.53a36.72,36.72,0,0,1,54.66,0Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-2" d="M42.36,86V89A40.73,40.73,0,0,1,11.58,62.42a40.62,40.62,0,0,1,0-26.72l2.64,1.53A37.74,37.74,0,0,0,42.36,86Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-2" d="M90.68,49.06a40.63,40.63,0,0,1-9.91,26.59A40.67,40.67,0,0,1,57.64,89V86A37.7,37.7,0,0,0,85.78,37.23l2.64-1.53A40.21,40.21,0,0,1,90.68,49.06Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-2" d="M80.77,22.47,78.11,24a37.61,37.61,0,0,0-56.22,0l-2.66-1.53A40.67,40.67,0,0,1,42.36,9.11a40.34,40.34,0,0,1,15.28,0A40.67,40.67,0,0,1,80.77,22.47Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-3" d="M42.36,89.72v3a44.54,44.54,0,0,1-34-28.47h0a44.3,44.3,0,0,1,0-30.46L11,35.35A41.41,41.41,0,0,0,42.36,89.72Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-3" d="M94.37,49.06a44,44,0,0,1-2.7,15.23h0a44.54,44.54,0,0,1-34,28.47v-3A41.37,41.37,0,0,0,89,35.35l2.64-1.52A44,44,0,0,1,94.37,49.06Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-3" d="M84,20.6l-2.64,1.52A41.34,41.34,0,0,0,57.64,8.4a41.46,41.46,0,0,0-15.28,0A41.34,41.34,0,0,0,18.63,22.12L16,20.6a44.3,44.3,0,0,1,68,0Z" transform="translate(-5.63 -4.69)"/>
          <path class="cls-4" d="M82,49.06A32,32,0,0,1,57.64,80.15a32,32,0,0,1-15.28,0A32,32,0,0,1,19.19,58h0A32,32,0,0,1,42.36,17.94a32,32,0,0,1,15.28,0A32,32,0,0,1,82,49.06Z" transform="translate(-5.63 -4.69)"/>
        </svg>
      `;
  }

  function getClusterStyles(userColor) {
    return [
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 1)
        )}`,
        height: 50,
        width: 50,
        textColor: "#FFFFFF", // Text color inside the SVG
        textSize: 14,
        anchorText: [0, 0],
      },
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 2)
        )}`,
        height: 60,
        width: 60,
        textColor: "#FFFFFF",
        textSize: 14,
        anchorText: [0, 0],
      },
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 3)
        )}`,
        height: 70,
        width: 70,
        textColor: "#FFFFFF",
        textSize: 16,
        anchorText: [0, 0],
      },
    ];
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    createPartnersPageResponse(id, { ...data, companyId: user?.company?._id })
      .then((res) => {
        success("Your response has been submitted successfully");
        setData(initialData);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {showPage ? (
        <div>
          <div
            className={`z-20 absolute bg-white rounded-full border shadow-sm pointer-cursor p-3 top-4 ${
              companyInfoSidebarOpen
                ? "lg:left-[21%] md:left-[42%] left-[80%]"
                : "left-[5px]"
            } text-green-600 hover:bg-gray-50 cursor-pointer`}
          >
            <button
              className={`flex items-center justify-center`}
              onClick={() => {
                setCompanyInfoSidebarOpen(!companyInfoSidebarOpen);
              }}
            >
              {companyInfoSidebarOpen ? (
                <IoIosArrowBack />
              ) : (
                <IoIosArrowForward />
              )}
            </button>
          </div>
          <div className="grid grid-cols-4">
            <div
              className={`${
                companyInfoSidebarOpen
                  ? "lg:col-span-1 md:col-span-2 col-span-4"
                  : "hidden"
              }`}
            >
              <div className="mx-6 my-8">
                <div>
                  <img
                    src={`${baseURL}s3/images/${user?.company?.logo || ""}`}
                    alt="User Profile"
                    className="rounded-full w-20 h-20"
                  />
                </div>
                <div className="flex flex-col gap-2 my-4">
                  <h1 className="text-lg text-green-600 font-semibold">
                    {user?.company?.name}
                  </h1>
                  <p>{user?.company?.description}</p>
                </div>
                <hr />
                <div className="flex flex-col gap-2 my-4">
                  <h1 className="text-lg font-semibold">Head Office</h1>
                  {offices?.map((office, index) => (
                    <div key={index}>
                      <h2>{office?.name}</h2>
                      <p>{office?.address}</p>
                    </div>
                  ))}
                </div>

                <div className="p-px rounded-b-3xl bg-gradient-to-b from-[#FFFFFF] to-[#FFB800]">
                  <div className="bg-white pb-8 rounded-[calc(1.5rem-1px)]">
                    <div className="flex justify-center">
                      <div className="w-64 bg-green-600 text-white text-center py-3 rounded-b-3xl">
                        <h1 className="lg:text-xl md:text-lg text-md font-semibold">
                          Get in Touch with Us
                        </h1>
                      </div>
                    </div>
                    <form className="mt-4 mx-4" onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <input
                          type="text"
                          placeholder="Name"
                          value={data?.name}
                          onChange={handleChange}
                          name="name"
                          required
                          className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                      </div>
                      <div className="mb-4">
                        <input
                          type="email"
                          placeholder="Email"
                          value={data?.email}
                          onChange={handleChange}
                          name="email"
                          required
                          className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="w-36 text-center rounded-full lg:px-10 md:px-10 px-5 py-2 font-bold text-white lg:text-base md:text-base text-xs bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
                        >
                          {loader ? (
                            <div className="flex justify-center items-center">
                              <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="22"
                                visible={true}
                              />
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                companyInfoSidebarOpen
                  ? "lg:col-span-3 md:col-span-2 col-span-4"
                  : "col-span-4"
              } overflox-hidden`}
            >
              <ProjectDetailsPopup
                open={projectDetailsPopupOpen}
                setOpen={setProjectDetailsPopupOpen}
                projectId={projectDetailsId}
                serviceIcon={selectedProject?.serviceData?.projectMarkerIcon}
                textColour={textColour}
                showProjectCost={filterPreset?.showProjectCost}
              />
              <OfficeDetailsPopup
                open={officeDetailsPopupOpen}
                setOpen={setOfficeDetailsPopupOpen}
                officeId={officeId}
              />
              <div
                className={`z-10 absolute ${
                  companyInfoSidebarOpen ? "px-4" : "px-12"
                } py-4`}
              >
                <div className=" w-40 lg:w-auto md:w-auto flex-wrap flex items-center">
                  <div className="mb-2 lg:mb-0 md:mb-0 w-52 md:w-80 lg:w-80 relative mx-3 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <CiSearch
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <Autocomplete
                      onLoad={onLoadAutoComplete}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        type="text"
                        name="text"
                        id="search"
                        className="block w-full rounded-md border-0 py-2.5 pl-10 text-gray-900  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Address"
                      />
                    </Autocomplete>
                  </div>

                  {boundsChanged && (
                    <button
                      className={`lg:mt-0 md:mt-0 mt-2 flex items-center justify-center bg-white hover:bg-gray-100 font-semibold py-2.5 px-4 rounded-md shadow-md lg:ml-2 md:ml-2 ml-0`}
                      style={{ color: `${textColour}` }}
                      onClick={() => {
                        getProjectsFromDatabase();
                      }}
                      disabled={projectsLoading}
                    >
                      {/* If projectsLoading, move this icon in circle */}
                      {projectsLoading ? (
                        <FaSearchLocation className="animate-spin" />
                      ) : (
                        <FaSearchLocation />
                      )}
                      <span
                        className={`lg:text-base md:text-base text-xs lg:ml-2 md:ml-2 ml-2`}
                      >
                        Search This Area
                      </span>
                    </button>
                  )}
                </div>
              </div>

              <div>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={mapCenter}
                  zoom={10}
                  onLoad={(map) => setMap(map)}
                  options={options}
                  // ref={mapRef}
                  onDragStart={() => {
                    setSearchFromDb(false);
                  }}
                  onDragEnd={() => {
                    // Set map center to the center of the map
                    setMapCenter({
                      lat: map?.getCenter()?.lat(),
                      lng: map?.getCenter()?.lng(),
                    });
                  }}
                  onZoomChanged={debounce(() => {
                    setSearchFromDb(false);
                  }, 2000)}
                  onBoundsChanged={() => {
                    setBoundsChanged(true);
                    onBoundsChanged();
                  }}
                >
                  {mapClustererColour !== "" && (
                    <MarkerClustererF
                      options={{
                        styles: getClusterStyles(mapClustererColour), // Pass the hex color code
                      }}
                      // imagePath={markerClusterImage}
                      onClick={handleClusterClick}
                    >
                      {(clusterer) =>
                        projects.map((project, index) => {
                          const [lng, lat] = project.coordinates;
                          const position = {
                            lat: parseFloat(lat),
                            lng: parseFloat(lng),
                          };

                          return (
                            <MarkerF
                              key={index}
                              position={position}
                              clusterer={clusterer}
                              icon={{
                                url: `${baseURL}s3/images/${project?.serviceData?.projectMarkerIcon}`,
                                scaledSize: { width: 60, height: 60 },
                              }}
                              project={project}
                              onClick={() => {
                                setProjectDetailsId(project._id);
                                setSelectedProject(project);
                                setProjectDetailsPopupOpen(true);
                              }}
                            />
                          );
                        })
                      }
                    </MarkerClustererF>
                  )}
                  {offices?.map((office, index) => (
                    <MarkerF
                      key={index}
                      position={{
                        lat: office?.coordinates[1],
                        lng: office?.coordinates[0],
                      }}
                      icon={{
                        url: `${baseURL}s3/images/${officeMarkerIcon}`,
                        scaledSize: { width: 60, height: 60 },
                      }}
                      onClick={() => {
                        setOfficeId(office?._id);
                        setOfficeDetailsPopupOpen(true);
                      }}
                    />
                  ))}
                  {projectsPopupPosition && (
                    <Transition.Root
                      show={clusterProjectsPopupOpen}
                      as={Fragment}
                      appear
                    >
                      <Dialog
                        className="relative z-10"
                        onClose={setClusterProjectsPopupOpen}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                              <Combobox>
                                <div className="relative flex justify-between items-center">
                                  <h2 className="text-lg font-medium text-gray-900 p-3">
                                    Projects in Cluster
                                  </h2>
                                  <XCircleIcon
                                    className="h-5 w-5 text-gray-400 mr-4 cursor-pointer"
                                    aria-hidden="true"
                                    onClick={closePopup}
                                  />
                                </div>
                                {selectedClusterProjects.length > 0 && (
                                  <Combobox.Options
                                    static
                                    className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3"
                                  >
                                    {selectedClusterProjects.map((project) => (
                                      <Combobox.Option
                                        key={project._id}
                                        value={project}
                                        className={({ active }) =>
                                          classNames(
                                            "flex cursor-pointer select-none rounded-xl p-3",
                                            active && "bg-gray-100"
                                          )
                                        }
                                        onClick={() => {
                                          setProjectDetailsId(project._id);
                                          setProjectDetailsPopupOpen(true);
                                        }}
                                      >
                                        {({ active }) => (
                                          <>
                                            <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-green-50">
                                              <img
                                                src={`${baseURL}s3/images/${project?.serviceData?.projectMarkerIcon}`}
                                                className="h-8 w-8 text-white"
                                                aria-hidden="true"
                                              />
                                            </div>
                                            <div className="ml-4 flex-auto">
                                              <p
                                                className={classNames(
                                                  "text-sm font-medium",
                                                  active
                                                    ? "text-gray-900"
                                                    : "text-gray-700"
                                                )}
                                              >
                                                {project?.serviceData?.name}
                                              </p>
                                              <p
                                                className={classNames(
                                                  "text-sm",
                                                  active
                                                    ? "text-gray-700"
                                                    : "text-gray-500"
                                                )}
                                              >
                                                {project?.workType?.name}
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </Combobox.Options>
                                )}
                              </Combobox>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </Dialog>
                    </Transition.Root>
                  )}
                </GoogleMap>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="text-center">
            You are not allowed to view this page
          </div>
          <div className="text-center">
            <p>Contact</p>
            <p>info@promapper.com</p>
          </div>
        </>
      )}
    </>
  );
};

export default PartnersLandingPage;
