import * as type from "../types";

const userReducer = (users = [], action) => {
  switch (action.type) {
    case type.CREATE_USER:
      return [action.payload, ...users];
    case type.GET_USERS:
      return action.payload.reverse();
    case type.DELETE_USER:
      return users.filter((user) => user._id !== action.payload);
    case type.UPDATE_USER:
      return users.map((user) =>
        user._id === action.payload._id ? action.payload : user
      );
    default:
      return users;
  }
};

export default userReducer;
