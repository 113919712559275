import * as type from "../types";

const authReducer = (state = [], action) => {
  switch (action.type) {
    case type.AUTH: {
      localStorage.setItem(
        action?.payload?.result?.role,
        JSON.stringify({ ...action?.payload })
      );
      return { ...action.payload };
    }
    case type.LOGOUT: {
      localStorage.removeItem(action.payload);
      return [];
    }
    default:
      return state;
  }
};

export default authReducer;
