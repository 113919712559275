import React, { useState, useEffect, Fragment } from "react";
import Slider from "react-slick";
import { baseURL, getBuildingRoofSize } from "../../../redux/api";
import {
  GoogleMap,
  MarkerClustererF,
  MarkerF,
  InfoWindowF,
  useGoogleMap,
} from "@react-google-maps/api";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import ProjectDetailsPopup from "../../UserProjectsMap/ProjectDetailsPopup";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = {
  disableDefaultUI: true,
  // minZoom: 10,
  maxZoom: 14,
  mapId: "a216e88580f77d70",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
};
const RoofingResults = ({
  formData,
  logosSettings,
  brandsOffered,
  company,
  appearanceSettings,
  landingPageDetails,
}) => {
  const [map, setMap] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [projectDetailsId, setProjectDetailsId] = useState(null);
  const [projectDetailsPopupOpen, setProjectDetailsPopupOpen] = useState(false);
  const [clusterProjectsPopupOpen, setClusterProjectsPopupOpen] =
    useState(false);
  const [projectsPopupPosition, setProjectsPopupPosition] = useState(null);
  const [selectedClusterProjects, setSelectedClusterProjects] = useState([]);
  const getWidthPercentage = (cost) => {
    return (cost / formData?.projectsCosts?.max) * 100 + "%";
  };
  const formatNumber = (number) => {
    return Math.trunc(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const closePopup = () => {
    setProjectsPopupPosition(null);
  };
  const handleClusterClick = (clusterer) => {
    const zoom = map.getZoom();

    if (landingPageDetails.allowMapZoom && zoom < 14) {
      // If zooming is allowed and current zoom is less than 14, zoom in to show markers
      // map.setZoom(zoom + 2);
      // map.panTo(clusterer.getCenter());
    } else {
      const markersInCluster = clusterer.getMarkers();

      // Check if there are markers in the cluster
      if (!markersInCluster || markersInCluster.length === 0) {
        console.error("No markers in cluster");
        return;
      }

      // Retrieve original marker data from the markers in the cluster
      const markerData = markersInCluster.map((marker) => marker.customData);

      // Get center of cluster
      const center = clusterer.getCenter();

      // Prevent default cluster zoom behavior
      window.google.maps.event.addListenerOnce(map, "idle", () => {
        // Set zoom after preventing default behavior
        setTimeout(() => {
          map.setZoom(zoom);
        }, 0);
      });

      map.panTo(center);
      console.log("Cluster clicked", markerData);
      setClusterProjectsPopupOpen(true);
      setSelectedClusterProjects(markerData);

      // Calculate the offset for the popup position (move it slightly to the right)
      const offsetLng = center.lng() + 0.0002; // Adjust as needed

      // Set popup position to the right side and above the cluster
      setProjectsPopupPosition({
        lat: center.lat(),
        lng: offsetLng,
      });
    }
  };

  function generateClusterIcon(color, text) {
    return `
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.74 88.08">
        <defs>
          <style>
            .cls-1, .cls-2, .cls-3, .cls-4 { fill: ${color}; }
            .cls-1 { opacity: 0.8; }
            .cls-2 { opacity: 0.6; }
            .cls-3 { opacity: 0.4; }
          </style>
        </defs>
        <path class="cls-1" d="M42.36,82v3.07A36.95,36.95,0,0,1,15,60.44h0a36.81,36.81,0,0,1,0-22.74l2.66,1.53A33.84,33.84,0,0,0,42.36,82Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-1" d="M86.79,49.06a36.86,36.86,0,0,1-29.15,36V82A33.81,33.81,0,0,0,82.33,39.22L85,37.69A36.58,36.58,0,0,1,86.79,49.06Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-1" d="M77.33,24.46,74.67,26a33.73,33.73,0,0,0-49.34,0l-2.66-1.53a36.72,36.72,0,0,1,54.66,0Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-2" d="M42.36,86V89A40.73,40.73,0,0,1,11.58,62.42a40.62,40.62,0,0,1,0-26.72l2.64,1.53A37.74,37.74,0,0,0,42.36,86Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-2" d="M90.68,49.06a40.63,40.63,0,0,1-9.91,26.59A40.67,40.67,0,0,1,57.64,89V86A37.7,37.7,0,0,0,85.78,37.23l2.64-1.53A40.21,40.21,0,0,1,90.68,49.06Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-2" d="M80.77,22.47,78.11,24a37.61,37.61,0,0,0-56.22,0l-2.66-1.53A40.67,40.67,0,0,1,42.36,9.11a40.34,40.34,0,0,1,15.28,0A40.67,40.67,0,0,1,80.77,22.47Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-3" d="M42.36,89.72v3a44.54,44.54,0,0,1-34-28.47h0a44.3,44.3,0,0,1,0-30.46L11,35.35A41.41,41.41,0,0,0,42.36,89.72Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-3" d="M94.37,49.06a44,44,0,0,1-2.7,15.23h0a44.54,44.54,0,0,1-34,28.47v-3A41.37,41.37,0,0,0,89,35.35l2.64-1.52A44,44,0,0,1,94.37,49.06Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-3" d="M84,20.6l-2.64,1.52A41.34,41.34,0,0,0,57.64,8.4a41.46,41.46,0,0,0-15.28,0A41.34,41.34,0,0,0,18.63,22.12L16,20.6a44.3,44.3,0,0,1,68,0Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-4" d="M82,49.06A32,32,0,0,1,57.64,80.15a32,32,0,0,1-15.28,0A32,32,0,0,1,19.19,58h0A32,32,0,0,1,42.36,17.94a32,32,0,0,1,15.28,0A32,32,0,0,1,82,49.06Z" transform="translate(-5.63 -4.69)"/>
      </svg>
    `;
  }

  function getClusterStyles(userColor) {
    return [
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 1)
        )}`,
        height: 50,
        width: 50,
        textColor: "#FFFFFF", // Text color inside the SVG
        textSize: 14,
        anchorText: [0, 0],
      },
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 2)
        )}`,
        height: 60,
        width: 60,
        textColor: "#FFFFFF",
        textSize: 14,
        anchorText: [0, 0],
      },
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 3)
        )}`,
        height: 70,
        width: 70,
        textColor: "#FFFFFF",
        textSize: 16,
        anchorText: [0, 0],
      },
    ];
  }

  return (
    <>
      <ProjectDetailsPopup
        open={projectDetailsPopupOpen}
        setOpen={setProjectDetailsPopupOpen}
        projectId={projectDetailsId}
        serviceIcon={appearanceSettings?.icon}
        textColour={appearanceSettings?.headingTextColour}
        showProjectCost={landingPageDetails?.showProjectCost}
      />
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={100}
            height={100}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-600">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div className="xl:mx-auto xl:max-w-7xl my-6 mx-4">
        <div className="p-4 rounded-lg">
          <div className="grid md:grid-cols-10 grid-cols-10 gap-4">
            <div className="md:col-span-7 col-span-10 bg-blue-100 rounded-md md:h-full h-[400px]">
              <GoogleMap
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "15px",
                }}
                options={options}
                center={{
                  lat: formData?.coordinates?.lat,
                  lng: formData?.coordinates?.lng,
                }}
                zoom={10}
                onLoad={(map) => setMap(map)}
              >
                <MarkerClustererF
                  options={{
                    styles: getClusterStyles(appearanceSettings?.primaryColour), // Pass the hex color code
                  }}
                  onClick={handleClusterClick}
                >
                  {(clusterer) =>
                    formData?.projects?.map((project, index) => {
                      const [lng, lat] = project.coordinates;
                      const position = {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng),
                      };
                      return (
                        <MarkerF
                          key={project._id || index}
                          position={position}
                          clusterer={clusterer}
                          title={project?.address}
                          icon={{
                            url: `${baseURL}s3/images/${appearanceSettings?.icon}`,
                            scaledSize: { width: 60, height: 60 },
                          }}
                          onClick={() => {
                            setSelectedProjectDetails(project);
                            setProjectDetailsId(project._id);
                            setProjectDetailsPopupOpen(true);
                          }}
                          onLoad={(markerF) => {
                            markerF.customData = project; // Attach original marker data
                          }}
                        />
                      );
                    })
                  }
                </MarkerClustererF>
                {projectsPopupPosition && (
                  <Transition.Root
                    show={clusterProjectsPopupOpen}
                    as={Fragment}
                    appear
                  >
                    <Dialog
                      className="relative z-50" // Changed z-index to 50
                      onClose={setClusterProjectsPopupOpen}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                      </Transition.Child>

                      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Combobox>
                              <div className="relative flex justify-between items-center">
                                <h2 className="text-lg font-medium text-gray-900 p-3">
                                  Projects in Cluster
                                </h2>
                                <XCircleIcon
                                  className="h-5 w-5 text-gray-400 mr-4 cursor-pointer"
                                  aria-hidden="true"
                                  onClick={closePopup}
                                />
                              </div>
                              {selectedClusterProjects.length > 0 && (
                                <Combobox.Options
                                  static
                                  className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3"
                                >
                                  {selectedClusterProjects.map((project) => (
                                    <Combobox.Option
                                      key={project._id}
                                      value={project}
                                      className={({ active }) =>
                                        classNames(
                                          "flex cursor-pointer select-none rounded-xl p-3",
                                          active && "bg-gray-100"
                                        )
                                      }
                                      onClick={() => {
                                        setProjectDetailsId(project._id);
                                        setProjectDetailsPopupOpen(true);
                                      }}
                                    >
                                      {({ active }) => (
                                        <>
                                          <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-green-50">
                                            <img
                                              src={`${baseURL}s3/images/${appearanceSettings?.icon}`}
                                              className="h-8 w-8 text-white"
                                              aria-hidden="true"
                                            />
                                          </div>
                                          <div className="ml-4 flex-auto">
                                            <p
                                              className={classNames(
                                                "text-sm font-medium",
                                                active
                                                  ? "text-gray-900"
                                                  : "text-gray-700"
                                              )}
                                            >
                                              {project?.serviceData?.name}
                                            </p>
                                            <p
                                              className={classNames(
                                                "text-sm",
                                                active
                                                  ? "text-gray-700"
                                                  : "text-gray-500"
                                              )}
                                            >
                                              {
                                                project?.serviceData?.workTypes?.find(
                                                  (w) =>
                                                    w._id === project?.workType
                                                )?.name
                                              }
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))}
                                </Combobox.Options>
                              )}
                            </Combobox>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>
                )}
              </GoogleMap>
            </div>
            <div className="md:col-span-3 col-span-10">
              <div className="flex flex-col gap-y-4">
                <div className="bg-orange-100 rounded-md py-2 px-4">
                  <h3 className="text-sm font-normal text-gray-600">
                    Your Estimated Roof Size
                  </h3>
                  <p className="font-medium text-lg">
                    {formatNumber(formData?.estimatedRoofArea)} Sqft
                  </p>
                </div>
                <div className="bg-sky-200 rounded-md py-2 px-4">
                  <h3 className="text-sm font-normal text-gray-600">
                    Number of Similar Projects
                  </h3>
                  <p className="font-medium text-lg">
                    {formData?.numberOfRelevantProjects}
                  </p>
                </div>
                <div className="bg-white rounded-md py-2 px-4">
                  <h3 className="text-sm font-normal text-gray-600">
                    Time in Business
                  </h3>
                  {company?.launchYear && (
                    <p className="font-medium text-lg">{`${
                      new Date().getFullYear() - company?.launchYear
                    }+ years`}</p>
                  )}
                </div>
                <div className="bg-purple-300 py-2 px-4 rounded-md mb-2 flex-1">
                  <p className="text-sm font-normal text-gray-600">
                    Project Costs
                  </p>
                  <div className="space-y-2 mt-5">
                    {/* High */}
                    <div className="flex items-center justify-between">
                      <div className="text-left w-8">High</div>
                      <div className="flex-grow mx-4 flex">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: getWidthPercentage(
                              formData?.projectsCosts?.max
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="text-right">
                        $ {formatNumber(formData?.projectsCosts?.max)}
                      </div>
                    </div>

                    {/* Med */}
                    <div className="flex items-center justify-between">
                      <div className="text-left w-8">Med</div>
                      <div className="flex-grow mx-4 flex">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: getWidthPercentage(
                              formData?.projectsCosts?.mid
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="text-right">
                        $ {formatNumber(formData?.projectsCosts?.mid)}
                      </div>
                    </div>

                    {/* Low */}
                    <div className="flex items-center justify-between">
                      <div className="text-left w-8">Low</div>
                      <div className="flex-grow mx-4 flex">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: getWidthPercentage(
                              formData?.projectsCosts?.min
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="text-right">
                        $ {formatNumber(formData?.projectsCosts?.min)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-md py-2 px-4">
                  <h3 className="text-sm font-normal text-gray-600">
                    Brands Used
                  </h3>
                  <div>
                    <div className="mt-2 slider-container">
                      <Slider {...logosSettings}>
                        {brandsOffered.map((brand, index) => (
                          <div key={index} className="flex justify-center">
                            <div className="mx-2 lg:col-span-4 md:col-span-6 col-span-12 h-full flex">
                              <div
                                className="h-full w-full"
                                style={{
                                  boxShadow: "0px 4px 34px 0px #0000000A",
                                  overflow: "visible", // Ensures that logos are fully visible
                                }}
                              >
                                {/* Image at the top with fixed height */}
                                <div className="w-full h-16 flex-shrink-0 p-1 flex items-center">
                                  {/* Ensures image height is consistent */}
                                  {brand?.logo ? (
                                    <img
                                      className="object-cover"
                                      src={`${baseURL}s3/images/${brand?.logo}`}
                                      alt={brand?.name}
                                    />
                                  ) : (
                                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                                      <span className="text-2xl font-bold text-gray-400">
                                        {brand?.name
                                          ? brand.name
                                              .split(" ")
                                              .map((word) => word[0])
                                              .join("")
                                              .toUpperCase()
                                          : "N/A"}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoofingResults;
