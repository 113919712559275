import * as types from "../types";

const initialService = {
  data: [],
  filters: [],
  loading: false,
  error: null,
};

const serviceReducer = (state = initialService, { type, payload }) => {
  switch (type) {
    case types.CREATE_SERVICE:
      return {
        ...state,
        data: [...state.data, payload],
      };
    case types.GET_SERVICES:
      return {
        ...state,
        data: payload.reverse(),
      };
    case types.GET_SERVICE_FILTERS:
      return {
        ...state,
        filters: payload,
      };
    case types.DELETE_SERVICE:
      return {
        ...state,
        data: state.data.filter((service) => service._id !== payload),
      };
    case types.UPDATE_SERVICE:
      return {
        ...state,
        data: state.data.map((service) =>
          service._id === payload._id ? payload : service
        ),
      };
    default:
      return state;
  }
};

export default serviceReducer;
