import * as type from "../types";

const roleReducer = (roles = [], action) => {
  switch (action.type) {
    case type.CREATE_ROLE:
      return [action.payload, ...roles];
    case type.GET_ROLES:
      return action.payload.reverse();
    case type.DELETE_ROLE:
      return roles.filter((role) => role._id !== action.payload);
    case type.UPDATE_ROLE:
      return roles.map((role) =>
        role._id === action.payload._id ? action.payload : role
      );
    default:
      return roles;
  }
};

export default roleReducer;
