import * as type from "../types";

const equipmentTypeReducer = (equipmentTypes = [], action) => {
  switch (action.type) {
    case type.CREATE_EQUIPMENTTYPE:
      return [action.payload, ...equipmentTypes];
    case type.GET_EQUIPMENTTYPES:
      return action.payload.reverse();
    case type.DELETE_EQUIPMENTTYPE:
      return equipmentTypes.filter(
        (equipmentType) => equipmentType._id !== action.payload
      );
    case type.UPDATE_EQUIPMENTTYPE:
      return equipmentTypes.map((equipmentType) =>
        equipmentType._id === action.payload._id
          ? action.payload
          : equipmentType
      );
    default:
      return equipmentTypes;
  }
};

export default equipmentTypeReducer;
