export const AUTH = "AUTH";
export const LOGOUT = "AUTH";

export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICE_FILTERS = "GET_SERVICE_FILTERS";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const GET_PROJECTS = "GET_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const GET_EQUIPMENTTYPES = "GET_EQUIPMENTTYPES";
export const CREATE_EQUIPMENTTYPE = "CREATE_EQUIPMENTTYPE";
export const UPDATE_EQUIPMENTTYPE = "UPDATE_EQUIPMENTTYPE";
export const DELETE_EQUIPMENTTYPE = "DELETE_EQUIPMENTTYPE";

export const GET_EQUIPMENTMODELS = "GET_EQUIPMENTMODELS";
export const CREATE_EQUIPMENTMODEL = "CREATE_EQUIPMENTMODEL";
export const UPDATE_EQUIPMENTMODEL = "UPDATE_EQUIPMENTMODEL";
export const DELETE_EQUIPMENTMODEL = "DELETE_EQUIPMENTMODEL";

export const GET_EQUIPMENTBRANDS = "GET_EQUIPMENTBRANDS";
export const CREATE_EQUIPMENTBRAND = "CREATE_EQUIPMENTBRAND";
export const UPDATE_EQUIPMENTBRAND = "UPDATE_EQUIPMENTBRAND";
export const DELETE_EQUIPMENTBRAND = "DELETE_EQUIPMENTBRAND";

export const GET_WORKTYPES = "GET_WORKTYPES";
export const CREATE_WORKTYPE = "CREATE_WORKTYPE";
export const UPDATE_WORKTYPE = "UPDATE_WORKTYPE";
export const DELETE_WORKTYPE = "DELETE_WORKTYPE";

export const GET_CONTACTS = "GET_CONTACTS";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

export const GET_OFFICES = "GET_OFFICES";
export const CREATE_OFFICE = "CREATE_OFFICE";
export const UPDATE_OFFICE = "UPDATE_OFFICE";
export const DELETE_OFFICE = "DELETE_OFFICE";

export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const GET_ROLES = "GET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

export const GET_OPTIONSETS = "GET_OPTIONSETS";
export const CREATE_OPTIONSET = "CREATE_OPTIONSET";
export const UPDATE_OPTIONSET = "UPDATE_OPTIONSET";
export const DELETE_OPTIONSET = "DELETE_OPTIONSET";

export const GET_COMPANIES = "GET_COMPANIES";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";

export const GET_DYNAMICFIELDS = "GET_DYNAMICFIELDS";
